import React, { useState } from "react";
import axios from "axios";
import {
  useStripe,
  useElements,
  PaymentElement,
  AddressElement,
} from "@stripe/react-stripe-js";
import { baseFrontendUrl } from "../config/env";

const SendTipCheckoutForm = ({ creatorUsername }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const [isAmountValid, setIsAmountValid] = useState(true);
  const [amount, setAmount] = useState("");
  const [messageText, setMessageText] = useState("");

  const handleError = (error) => {
    setLoading(false);
    setErrorMessage(error.message);
  };
  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const amountInDollars = parseFloat(amount);

    // Check if the input is a valid number and within the valid range
    if (
      isNaN(amountInDollars) ||
      amountInDollars <= 10.0 ||
      amountInDollars >= 1000.0
    ) {
      setIsAmountValid(false);
      return;
    }

    // Round the input to two decimal places
    const roundedAmountInDollars = Math.round(amountInDollars * 100) / 100;

    // Convert dollars to cents
    const amountInCents = Math.round(roundedAmountInDollars * 100);

    setLoading(true);

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      handleError(submitError);
      return;
    }

    // Create the PaymentIntent and obtain clientSecret
    // Create subscription
    const { data } = await axios({
      method: "post",
      url: `/api/users/create-tip-intent/${creatorUsername}`,
      data: {
        amount: amountInCents,
        message: messageText,
      },
    });

    const { client_secret } = data;

    // Confirm the PaymentIntent using the details collected by the Payment Element
    const { error } = await stripe.confirmPayment({
      elements,
      clientSecret: client_secret,
      confirmParams: {
        return_url: `${baseFrontendUrl}/subscriptions?choice=SENT%20TIPS`,
      },
    });

    if (error) {
      // This point is only reached if there's an immediate error when
      // confirming the payment. Show the error to your customer (for example, payment details incomplete)
      handleError(error);
    } else {
      // Your customer is redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer is redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };
  return (
    <>
      <h6 className="text-muted mt-3">SEND CREATOR A TIP</h6>

      <div className="border rounded">
        <div className="p-3">
          <div class="row">
            <div class="col-md-8">
              <div class="mb-3">
                <label for="tipAmount" class="form-label">
                  Tip Amount (in dollars)
                </label>
                <input
                  type="number"
                  className={`form-control ${
                    isAmountValid ? "" : "is-invalid"
                  }`}
                  onChange={(event) => {
                    setAmount(event.target.value);
                    setIsAmountValid(true);
                  }}
                  id="tipAmount"
                  name="tipAmount"
                  placeholder="Enter tip amount"
                ></input>
                <div className="invalid-feedback">
                  Invalid tip. Must be greater than $10 and less than $1000
                </div>
              </div>
            </div>
            <div class="col-md-10">
              <div class="mb-3">
                <label for="tipMessage" class="form-label">
                  Message (optional)
                </label>
                <textarea
                  className="form-control"
                  id="tipMessage"
                  name="tipMessage"
                  rows="3"
                  placeholder="Enter your message"
                  defaultValue={messageText}
                  onBlur={(event) => {
                    setMessageText(event.target.value);
                  }}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h6 className="text-muted mt-3">PAYMENT INFORMATION</h6>
      <form onSubmit={handleSubmit}>
        <AddressElement
          options={{ mode: "shipping", allowedCountries: ["US"] }}
        />
        <PaymentElement />

        {loading ? (
          <button
            class="btn btn-primary text-white mt-3"
            type="submit"
            disabled
          >
            <span class="spinner-border spinner-border-sm me-2"></span>
            <span role="status">Send tip</span>
          </button>
        ) : (
          <button
            disabled={!stripe}
            class="btn btn-primary text-white mt-3"
            type="submit"
          >
            Send tip
          </button>
        )}

        {/* Show error message to your customers */}
        {errorMessage && <div>{errorMessage}</div>}
      </form>
    </>
  );
};

export default SendTipCheckoutForm;

import React, { useEffect } from "react";
import Chats from "../components/Chats";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

const ChatsScreen = () => {
  const navigate = useNavigate();
  const userLogin = useSelector((state) => state.userLogin);
  const { isAuthenticated, loading: userLoading } = userLogin;

  useEffect(() => {
    if (isAuthenticated === false) {
      navigate("/login");
    }
  }, [isAuthenticated]);
  return (
    <div className="container justify-content-center col-lg-9 mt-4">
      {userLoading && <h1>Loading</h1>}
      {isAuthenticated === true && <Chats></Chats>}
    </div>
  );
};

export default ChatsScreen;

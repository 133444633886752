import React, { useEffect, useRef } from "react";

const CloudinaryVideoPlayer = ({ url }) => {
  const cloudinaryVideoRef = useRef();
  const videoRef = useRef();
  useEffect(() => {
    if (cloudinaryVideoRef.current) return;
    cloudinaryVideoRef.current = window.cloudinary;
    cloudinaryVideoRef.current.videoPlayer(videoRef.current, {
      cloud_name: "dx4nlfeak",
      // aspect_ratio: "16:9",
      transformation: {
        crop: "pad",
        aspect_ratio: "16:9",
      },
      // fluid: true,
    });
  }, []);
  return (
    <video
      ref={videoRef}
      data-cld-public-id={url}
      // data-cld-transformation='{ "aspect_ratio": "16:9", "crop": "pad" }'
      // className="cld-video-player cld-fluid"
      // width={"100%"}
      // height={"100%"}
      width={332}
      height={187}
      // style={{ width: "100%", height: "100%" }}
      controls={true}
      // fluid={true}
      // translate={{ aspect_ratio: "9:16", crop: "fit" }}
    ></video>
  );
};

// https://res.cloudinary.com/dx4nlfeak/video/upload/v1712957017/solostreamerProfilePictures/user/das18wmwjkdhssmefjof.mp4

export default CloudinaryVideoPlayer;

import axios from "axios";
import {
  EDIT_CREATOR_INFO_FAIL,
  EDIT_CREATOR_INFO_REQUEST,
  EDIT_CREATOR_INFO_SUCCESS,
  GET_CREATOR_DASHBOARD_INFORMATION_FAIL,
  GET_CREATOR_DASHBOARD_INFORMATION_REQUEST,
  GET_CREATOR_DASHBOARD_INFORMATION_SUCCESS,
  GET_CREATOR_INFO_REQUEST,
  GET_CREATOR_INFO_SUCCESS,
  GET_CREATOR_PAGE_INFORMATION_FAIL,
  GET_CREATOR_PAGE_INFORMATION_REQUEST,
  GET_CREATOR_PAGE_INFORMATION_SUCCESS,
  GET_CREATOR_SUBSCRIPTION_PRICES_FAIL,
  GET_CREATOR_SUBSCRIPTION_PRICES_REQUEST,
  GET_CREATOR_SUBSCRIPTION_PRICES_SUCCESS,
  GET_CREATOR_TIP_SCREEN_INFORMATION_FAIL,
  GET_CREATOR_TIP_SCREEN_INFORMATION_REQUEST,
  GET_CREATOR_TIP_SCREEN_INFORMATION_SUCCESS,
  GET_USER_INFO_FAIL,
  GET_USER_INFO_REQUEST,
  GET_USER_INFO_SUCCESS,
  PAYOUT_CREATOR_FAIL,
  PAYOUT_CREATOR_REQUEST,
  PAYOUT_CREATOR_SUCCESS,
  SUBMIT_CREATOR_INFO_FAIL,
  SUBMIT_CREATOR_INFO_REQUEST,
  SUBMIT_CREATOR_INFO_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
} from "../constants/userConstants";

export const login = () => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });
    const { data } = await axios.get("/api/users/login/");
    if (data) {
      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      });
    } else {
      dispatch({ type: USER_LOGOUT });
    }
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: USER_LOGIN_FAIL,
      payload: message,
    });
  }
};

export const getUserInformation = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_USER_INFO_REQUEST,
    });
    const { data } = await axios.get("/api/users/user-information/");

    dispatch({
      type: GET_USER_INFO_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: GET_USER_INFO_FAIL,
      payload: message,
    });
  }
};
export const submitCreatorInformation = (submitInfo) => async (dispatch) => {
  try {
    dispatch({
      type: SUBMIT_CREATOR_INFO_REQUEST,
    });
    const { data } = await axios({
      method: "post",
      url: "/api/users/submit-creator-information",
      data: submitInfo,
    });

    dispatch({
      type: SUBMIT_CREATOR_INFO_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: SUBMIT_CREATOR_INFO_FAIL,
      payload: message,
    });
  }
};

export const editCreatorInformation = (updatedInfo) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_CREATOR_INFO_REQUEST,
    });
    const { data } = await axios({
      method: "post",
      url: "/api/users/edit-creator-information",
      data: updatedInfo,
    });

    dispatch({
      type: EDIT_CREATOR_INFO_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: EDIT_CREATOR_INFO_FAIL,
      payload: message,
    });
  }
};

export const getCreatorSubscriptionPrices = (userName) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CREATOR_SUBSCRIPTION_PRICES_REQUEST,
    });
    const { data } = await axios.get(
      `/api/users/get-subscription-prices/${userName}`
    );

    dispatch({
      type: GET_CREATOR_SUBSCRIPTION_PRICES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: GET_CREATOR_SUBSCRIPTION_PRICES_FAIL,
      payload: message,
    });
  }
};

export const getCreatorPageInformation = (userName) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CREATOR_PAGE_INFORMATION_REQUEST,
    });
    const { data } = await axios.get(
      `/api/users/creator-page-information/${userName}`
    );

    dispatch({
      type: GET_CREATOR_PAGE_INFORMATION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: GET_CREATOR_PAGE_INFORMATION_FAIL,
      payload: message,
    });
  }
};

export const payoutCreator = () => async (dispatch) => {
  try {
    dispatch({
      type: PAYOUT_CREATOR_REQUEST,
    });
    const { data } = await axios.get("/api/users/payout-creator");

    dispatch({
      type: PAYOUT_CREATOR_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: PAYOUT_CREATOR_FAIL,
      payload: message,
    });
  }
};

export const getCreatorDashboardInformation =
  (userName) => async (dispatch) => {
    try {
      dispatch({
        type: GET_CREATOR_DASHBOARD_INFORMATION_REQUEST,
      });
      const { data } = await axios.get(
        `/api/users/creator-dashboard-information/${userName}`
      );

      dispatch({
        type: GET_CREATOR_DASHBOARD_INFORMATION_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;

      dispatch({
        type: GET_CREATOR_DASHBOARD_INFORMATION_FAIL,
        payload: message,
      });
    }
  };

export const getTipCreationScreenInformation =
  (creatorUsername) => async (dispatch) => {
    try {
      dispatch({
        type: GET_CREATOR_TIP_SCREEN_INFORMATION_REQUEST,
      });
      const { data } = await axios.get(
        `/api/users/tip-creation-information/${creatorUsername}`
      );
      dispatch({
        type: GET_CREATOR_TIP_SCREEN_INFORMATION_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;

      dispatch({
        type: GET_CREATOR_TIP_SCREEN_INFORMATION_FAIL,
        payload: message,
      });
    }
  };

// Logout
export const logout = () => async (dispatch) => {
  // localStorage.removeItem("userLogin");
  const { data } = await axios.get("/api/logout/");
  dispatch({ type: USER_LOGOUT });
};

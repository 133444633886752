import { createStore, compose, combineReducers, applyMiddleware } from "redux";
// import thunk from "redux-thunk";
import { thunk } from "redux-thunk";
import { composeWithDevTools } from "@redux-devtools/extension";
import axios from "axios";
import { env_name } from "./config/env.js";

import {
  userLoginReducer,
  creatorSubscriptionPricesReducer,
  creatorPageInformationReducer,
  creatorDashboardInformationReducer,
  editCreatorInformationReducer,
  creatorPayoutReducer,
  tipCreationScreenReducer,
} from "./reducers/userReducers.js";
import { postReducer } from "./reducers/postReducers.js";

import { login } from "./actions/userActions";
import { baseUrl } from "./config/env.js";
import {
  cancelSubscriptionReducer,
  subscriptionCreationScreenReducer,
  subscriptionScreenInformationReducer,
} from "./reducers/subscriptionReducers.js";

const reducer = combineReducers({
  userLogin: userLoginReducer,
  creatorPageInformation: creatorPageInformationReducer,
  editCreatorInformation: editCreatorInformationReducer,
  creatorDashboardInformation: creatorDashboardInformationReducer,
  creatorSubscriptionPrices: creatorSubscriptionPricesReducer,
  creatorPayout: creatorPayoutReducer,
  subscriptionCreationScreen: subscriptionCreationScreenReducer,
  subscriptionScreenInformation: subscriptionScreenInformationReducer,
  tipCreationScreen: tipCreationScreenReducer,
  cancelSubscription: cancelSubscriptionReducer,
  post: postReducer,
});

const middleware = [thunk];
const middlewareEnhancer = applyMiddleware(...middleware);
const composedEnhancers =
  env_name === "prod"
    ? compose(middlewareEnhancer)
    : composeWithDevTools(middlewareEnhancer);

const store = createStore(reducer, composedEnhancers);

if (window.location.hostname !== "localhost") {
  axios.defaults.baseURL = baseUrl;
}
axios.defaults.withCredentials = true;
console.log(baseUrl, env_name);

store.dispatch(login());

export default store;

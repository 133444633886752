import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="container col-md-9 hide-during-print">
      <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
        <p className="col-md-4 mb-0 text-muted">
          &copy; 2024 SoloStreamer, Inc
        </p>

        <div className="col-md-4 d-flex align-items-center justify-content-center mb-0 me-md-auto ">
          {/* <h4 className="mx-2 mt-2">
            <Link to="https://twitter.com/reshup2023" className="text-dark">
              <i class="bi bi-twitter-x"></i>
            </Link>
          </h4> */}
          <h4 className="mx-2 mt-2">
            <Link
              to="https://www.instagram.com/solostreamer/"
              className="text-dark"
            >
              <i class="bi bi-instagram"></i>
            </Link>
          </h4>
          {/* <h4 className="mx-2 mt-2">
            <i class="bi bi-facebook"></i>
          </h4> */}
        </div>

        <ul className="nav col-md-4 justify-content-end">
          <li className="nav-item">
            <Link to="/" className="nav-link px-2 text-muted">
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/privacy" className="nav-link px-2 text-muted">
              Privacy
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/terms" className="nav-link px-2 text-muted">
              Terms
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/faq" className="nav-link px-2 text-muted">
              FAQs
            </Link>
          </li>
        </ul>
      </footer>
    </div>
  );
};

export default Footer;

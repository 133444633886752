import React, { useEffect, useState } from "react";

import EditProfileModal from "../components/EditProfileModal";
import CreatePostModal from "../components/CreatePostModal";
import NotFoundScreen from "./NotFoundScreen";
import EditBackgroundPhotoModal from "../components/EditBackgroundPhotoModal";
import EditSubscriptionModal from "../components/EditSubscriptionModal";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { getCreatorPageInformation } from "../actions/userActions";
import { findOrCreateChat } from "../utilities/chatService";
import CloudinaryVideoPlayer from "../components/CloudinaryVideoPlayer";
import Post from "../components/Post";
import DeletePostModal from "../components/DeletePostModal";
// TODO - FIO PRODUCTION
import { firestore, firebase } from "../firebase";

const ProfileScreen = () => {
  const params = useParams();
  const navigate = useNavigate();
  const userName = params.userName;
  const dispatch = useDispatch();

  const [showCurrent, setShowCurrent] = useState(false);
  const [postToDelete, setPostToDelete] = useState(null);

  const userLogin = useSelector((state) => state.userLogin);
  const { isAuthenticated, user, loading: userLoading } = userLogin;

  const creatorPageInformation = useSelector(
    (state) => state.creatorPageInformation
  );
  const {
    creatorPosts,
    photoPosts,
    creatorInformation,
    subscriptionPlans,
    totalLikes,
    numberOfPhotos,
    numberOfVideos,
    loading: profileLoading,
    isSubscribed,
    error,
  } = creatorPageInformation;
  useEffect(() => {
    dispatch(getCreatorPageInformation(userName));
  }, [userName, isAuthenticated]);

  // const getOrCreateUserProfile = async (userId, profileData) => {
  //   const userRef = firestore.collection("users").doc(userId);
  //   const userDoc = await userRef.get();

  //   if (!userDoc.exists) {
  //     await userRef.set(profileData);
  //     return { id: userId, ...profileData };
  //   } else {
  //     return { id: userId, ...userDoc.data() };
  //   }
  // };
  // const findOrCreateChat = async () => {
  //   const userId = user._id;
  //   const participantId = creatorInformation._id;
  //   if (!userId || !participantId) {
  //     return;
  //   }
  //   // Ensure both user profiles exist
  //   const userProfileData = {
  //     id: userId,
  //     firstName: user.firstName,
  //     lastName: user.lastName,
  //     userName: user.userName ? user.userName : "",
  //     profilePicture: user.profilePhoto,
  //   };
  //   const participantProfileData = {
  //     id: participantId,
  //     firstName: creatorInformation.firstName,
  //     lastName: creatorInformation.lastName,
  //     userName: creatorInformation.userName ? creatorInformation.userName : "",
  //     profilePicture: creatorInformation.profilePhoto,
  //   };
  //   await getOrCreateUserProfile(userId, userProfileData);
  //   await getOrCreateUserProfile(participantId, participantProfileData);

  //   const chatsRef = firestore.collection("chats");
  //   let chat = await chatsRef
  //     .where("participants", "array-contains", userId)
  //     .get();

  //   let chatDoc;

  //   chat.forEach((doc) => {
  //     const data = doc.data();
  //     if (data.participants.includes(participantId)) {
  //       chatDoc = doc;
  //     }
  //   });

  //   // If no chat is found, create a new one
  //   if (!chatDoc) {
  //     const chatData = {
  //       participants: [userId, participantId],
  //       createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  //     };
  //     chatData[`lastRead_${userId}`] =
  //       firebase.firestore.FieldValue.serverTimestamp();
  //     chatData[`lastRead_${participantId}`] =
  //       firebase.firestore.FieldValue.serverTimestamp();

  //     const newChatRef = await chatsRef.add(chatData);
  //     chatDoc = await newChatRef.get();
  //   }

  //   navigate(`/chats/${chatDoc.id}`);
  // };
  const handleChat = async () => {
    // await findOrCreateChat(user, creatorInformation, navigate);
    if (!user || !creatorInformation) {
      return;
    }
    await findOrCreateChat(user, creatorInformation, navigate);
  };
  return (
    <>
      {(userLoading || profileLoading) && (
        <>
          <div className="row mx-3 justify-content-center">
            <div className="col-md-6 mt-0 p-2">
              <div class="card mx-1">
                <img
                  src="https://res.cloudinary.com/dx4nlfeak/image/upload/v1714747269/Untitled_design_6_d5p1c3.png"
                  class="card-img-top"
                  alt="..."
                ></img>
                <div class="card-body">
                  <h5 class="card-title placeholder-glow">
                    <span class="placeholder col-6"></span>
                  </h5>
                  <p class="card-text placeholder-glow">
                    <span class="placeholder col-7"></span>
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-6"></span>
                    <span class="placeholder col-8"></span>
                  </p>
                  <a
                    class="btn btn-primary disabled placeholder col-6"
                    aria-disabled="true"
                  ></a>
                </div>
              </div>
            </div>
          </div>
          <hr></hr>

          <div class="row row-cols-2 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 g-3 mx-3">
            <div className="col">
              <div class="card mx-1">
                <img
                  src="https://res.cloudinary.com/dx4nlfeak/image/upload/v1714747269/Untitled_design_6_d5p1c3.png"
                  class="card-img-top"
                  alt="..."
                ></img>
                <div class="card-body">
                  <h5 class="card-title placeholder-glow">
                    <span class="placeholder col-6"></span>
                  </h5>
                  <p class="card-text placeholder-glow">
                    <span class="placeholder col-7"></span>
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-6"></span>
                    <span class="placeholder col-8"></span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col">
              <div class="card mx-1">
                <img
                  src="https://res.cloudinary.com/dx4nlfeak/image/upload/v1714747269/Untitled_design_6_d5p1c3.png"
                  class="card-img-top"
                  alt="..."
                ></img>
                <div class="card-body">
                  <h5 class="card-title placeholder-glow">
                    <span class="placeholder col-6"></span>
                  </h5>
                  <p class="card-text placeholder-glow">
                    <span class="placeholder col-7"></span>
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-6"></span>
                    <span class="placeholder col-8"></span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col">
              <div class="card mx-1">
                <img
                  src="https://res.cloudinary.com/dx4nlfeak/image/upload/v1714747269/Untitled_design_6_d5p1c3.png"
                  class="card-img-top"
                  alt="..."
                ></img>
                <div class="card-body">
                  <h5 class="card-title placeholder-glow">
                    <span class="placeholder col-6"></span>
                  </h5>
                  <p class="card-text placeholder-glow">
                    <span class="placeholder col-7"></span>
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-6"></span>
                    <span class="placeholder col-8"></span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col">
              <div class="card mx-1">
                <img
                  src="https://res.cloudinary.com/dx4nlfeak/image/upload/v1714747269/Untitled_design_6_d5p1c3.png"
                  class="card-img-top"
                  alt="..."
                ></img>
                <div class="card-body">
                  <h5 class="card-title placeholder-glow">
                    <span class="placeholder col-6"></span>
                  </h5>
                  <p class="card-text placeholder-glow">
                    <span class="placeholder col-7"></span>
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-6"></span>
                    <span class="placeholder col-8"></span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col">
              <div class="card mx-1">
                <img
                  src="https://res.cloudinary.com/dx4nlfeak/image/upload/v1714747269/Untitled_design_6_d5p1c3.png"
                  class="card-img-top"
                  alt="..."
                ></img>
                <div class="card-body">
                  <h5 class="card-title placeholder-glow">
                    <span class="placeholder col-6"></span>
                  </h5>
                  <p class="card-text placeholder-glow">
                    <span class="placeholder col-7"></span>
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-6"></span>
                    <span class="placeholder col-8"></span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col">
              <div class="card mx-1">
                <img
                  src="https://res.cloudinary.com/dx4nlfeak/image/upload/v1714747269/Untitled_design_6_d5p1c3.png"
                  class="card-img-top"
                  alt="..."
                ></img>
                <div class="card-body">
                  <h5 class="card-title placeholder-glow">
                    <span class="placeholder col-6"></span>
                  </h5>
                  <p class="card-text placeholder-glow">
                    <span class="placeholder col-7"></span>
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-6"></span>
                    <span class="placeholder col-8"></span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {!profileLoading && !error && creatorInformation && (
        <>
          <EditProfileModal
            creatorInformation={creatorInformation}
          ></EditProfileModal>
          <CreatePostModal
            userName={creatorInformation.userName}
          ></CreatePostModal>
          <EditBackgroundPhotoModal
            creatorInformation={creatorInformation}
          ></EditBackgroundPhotoModal>
          <EditSubscriptionModal
            creatorInformation={creatorInformation}
          ></EditSubscriptionModal>
          <DeletePostModal
            post={postToDelete}
            userName={creatorInformation.userName}
          ></DeletePostModal>
          <div className="col-md-6 offset-md-3">
            <div
              className="p-5  position-relative"
              style={{
                marginBottom: 50,
                backgroundImage: `url(${creatorInformation.backgroundPhoto})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                color: "#fff",
              }}
            >
              <div className="py-5">
                <h6>@{creatorInformation.userName}</h6>
                <p>
                  <i class="bi bi-image"></i> {photoPosts.length} &#183;{" "}
                  <i class="bi bi-camera-video"></i>{" "}
                  {creatorPosts.length - photoPosts.length} &#183;{" "}
                  <i class="bi bi-heart"></i> {totalLikes}
                </p>
              </div>
              <div class="position-absolute bottom-0 end-0">
                <h5>
                  {isAuthenticated &&
                    user &&
                    user.userName === creatorInformation.userName && (
                      <span
                        class="badge text-bg-light p-2 me-3"
                        data-bs-toggle="modal"
                        data-bs-target="#editBackgroundModal"
                        style={{ cursor: "pointer" }}
                      >
                        Edit background <i class="bi bi-pencil ms-2"></i>
                      </span>
                    )}
                </h5>
              </div>
              <div class="position-absolute top-100 start-0 translate-middle">
                <img
                  src={creatorInformation.profilePhoto}
                  class="bd-placeholder-img rounded-circle border-white"
                  alt=""
                  style={{ width: 75, height: 75, marginLeft: 100 }}
                ></img>
              </div>
            </div>
          </div>
          <div className="col-md-6 offset-md-3">
            <div className="px-3">
              <div class="pe-2 ">
                <div class="d-flex">
                  <div class="flex-grow-1">
                    <h5>@{creatorInformation.userName}</h5>
                  </div>
                  {isAuthenticated &&
                    user &&
                    // user._id != creatorInformation._id &&
                    isSubscribed &&
                    user._id !== creatorInformation._id && (
                      <div className="d-flex flex-row">
                        <p className="mb-0">
                          <span
                            class="badge text-bg-light p-2"
                            // data-bs-toggle="modal"
                            // data-bs-target="#editSubscriptionModal"
                            onClick={() => handleChat()}
                            style={{ cursor: "pointer" }}
                          >
                            Message <i class="bi bi-chat-dots ms-2"></i>
                          </span>
                        </p>
                        <p className="mb-0 ms-2">
                          <span
                            class="badge text-bg-light p-2"
                            // data-bs-toggle="modal"
                            // data-bs-target="#editSubscriptionModal"
                            onClick={() =>
                              navigate(
                                `/send-creator-tip/${creatorInformation.userName}`
                              )
                            }
                            style={{ cursor: "pointer" }}
                          >
                            Send tip <i class="bi bi-cash-stack ms-2"></i>
                          </span>
                        </p>
                      </div>
                    )}
                  {/* <div class="">
                    <h5>
                      <i
                        class="bi bi-arrow-up-right-square"
                        style={{ cursor: "pointer" }}
                      ></i>
                    </h5>
                  </div> */}
                </div>
              </div>
              {isAuthenticated &&
                user &&
                user.userName === creatorInformation.userName && (
                  <>
                    <Link to={`/dashboard/${creatorInformation.userName}`}>
                      <h5>
                        <span class="badge text-bg-light p-2">
                          View your dashboard{" "}
                          <i class="bi bi-bar-chart ms-2"></i>
                        </span>
                      </h5>
                    </Link>
                    <div class="hstack gap-3">
                      <div class="">
                        <h5>
                          <span
                            class="badge text-bg-light p-2"
                            data-bs-toggle="modal"
                            data-bs-target="#editProfileModal"
                            style={{ cursor: "pointer" }}
                          >
                            Edit profile <i class="bi bi-pencil ms-2"></i>
                          </span>
                        </h5>
                      </div>
                      <div class="">
                        <h5>
                          <span
                            class="badge text-bg-light p-2"
                            data-bs-toggle="modal"
                            data-bs-target="#createNewPostModal"
                            style={{ cursor: "pointer" }}
                          >
                            Create new post{" "}
                            <i class="bi bi-plus-square ms-2"></i>
                          </span>
                        </h5>
                      </div>
                    </div>
                  </>
                )}

              <p className="mb-1">{creatorInformation.profileBio}</p>
              <a
                class=" text-decoration-none text-primary dropdown-toggle"
                data-bs-toggle="collapse"
                data-bs-target="#publicProfileMoreCollapse"
                style={{ cursor: "pointer" }}
              >
                <strong>
                  <small>More info</small>
                </strong>
              </a>
              <div class="collapse" id="publicProfileMoreCollapse">
                ___________________________ <br></br>The copyright of the
                material contained on my page (Including All Images & Video
                material) is owned by {creatorInformation.userName}. You do NOT
                have permission to redistribute, post, use, copy, reproduce,
                print, or play any of my material outside of my page. Failure to
                comply with this WILL result in legal action taken against the
                person whose information you used to sign up with. Copyright
                2024. All rights reserved.
              </div>
              <div class="hstack gap-3">
                <div class="pe-2">
                  <h5
                    onClick={() =>
                      window.open(
                        creatorInformation.tiktok
                          ? `https://www.tiktok.com/@${creatorInformation.tiktok}/`
                          : "https://www.tiktok.com/",
                        "_blank"
                      )
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <span class="badge rounded-pill text-bg-light p-2">
                      <i class="bi bi-tiktok me-2"></i> Tiktok
                    </span>
                  </h5>
                </div>
                <div class="p-2">
                  <h5
                    onClick={() =>
                      window.open(
                        creatorInformation.twitter
                          ? `https://www.x.com/${creatorInformation.twitter}/`
                          : "https://www.x.com/",
                        "_blank"
                      )
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <span class="badge rounded-pill text-bg-light p-2">
                      <i class="bi bi-twitter me-2"></i>Twitter
                    </span>
                  </h5>
                </div>
                <div class="p-2">
                  <h5
                    onClick={() =>
                      window.open(
                        creatorInformation.instagram
                          ? `https://www.instagram.com/${creatorInformation.instagram}/`
                          : "https://www.instagram.com/",
                        "_blank"
                      )
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <span class="badge rounded-pill text-bg-light p-2">
                      <i class="bi bi-instagram me-2"></i> Instagram
                    </span>
                  </h5>
                </div>
              </div>
            </div>
            <hr></hr>
          </div>
          {isSubscribed === false && (
            <>
              <div className="col-md-6 offset-md-3">
                <div className="px-3">
                  <div className="d-flex flex-row mb-2">
                    <div className="align-self-center">
                      <p class="text-uppercase text-muted me-2 mb-0">
                        SUBSCRIPTION
                      </p>
                    </div>
                    {isAuthenticated &&
                      user &&
                      user.userName === creatorInformation.userName && (
                        <div>
                          <p className="mb-0">
                            <span
                              class="badge text-bg-light p-2"
                              data-bs-toggle="modal"
                              data-bs-target="#editSubscriptionModal"
                              style={{ cursor: "pointer" }}
                            >
                              Edit subscription amount{" "}
                              <i class="bi bi-pencil ms-2"></i>
                            </span>
                          </p>
                        </div>
                      )}
                  </div>
                  <div class="d-grid gap-2">
                    <Link
                      class="btn btn-primary rounded-3 p-2 text-white"
                      // type="button"
                      to={`/create-subscription/${creatorInformation.userName}/${subscriptionPlans[0]._id}`}
                    >
                      SUBSCRIBE $
                      {(subscriptionPlans[0].basePrice / 100).toFixed(2)} per
                      month
                    </Link>
                  </div>
                </div>
                <hr></hr>
              </div>
              <div className="col-md-6 offset-md-3">
                <div className="px-3">
                  <p class="text-uppercase text-muted">SUBSCRIPTION BUNDLES</p>
                  <div class="vstack gap-3">
                    <div>
                      <Link
                        class="btn btn-primary rounded-3 w-100 p-2 text-white"
                        // type="button"
                        to={`/create-subscription/${creatorInformation.userName}/${subscriptionPlans[1]._id}`}
                      >
                        3 MONTHS (10% off) $
                        {(subscriptionPlans[1].basePrice / 100).toFixed(2)}{" "}
                        total
                      </Link>
                    </div>
                    <div>
                      <Link
                        class="btn btn-primary rounded-3 w-100 p-2 text-white"
                        // type="button"
                        to={`/create-subscription/${creatorInformation.userName}/${subscriptionPlans[2]._id}`}
                      >
                        6 MONTHS (15% off) $
                        {(subscriptionPlans[2].basePrice / 100).toFixed(2)}{" "}
                        total
                      </Link>
                    </div>
                    <div>
                      <Link
                        class="btn btn-primary rounded-3 w-100 p-2 text-white"
                        // type="button"
                        to={`/create-subscription/${creatorInformation.userName}/${subscriptionPlans[3]._id}`}
                      >
                        12 MONTHS (20% off) $
                        {(subscriptionPlans[3].basePrice / 100).toFixed(2)}{" "}
                        total
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {isSubscribed === true && (
            <>
              <div className="col-md-6 offset-md-3">
                <div className="px-3">
                  <div className="d-flex flex-row mb-2">
                    <div className="align-self-center">
                      <p class="text-uppercase text-muted me-2 mb-0">
                        SUBSCRIPTION
                      </p>
                    </div>
                    {isAuthenticated &&
                      user &&
                      user.userName === creatorInformation.userName && (
                        <div>
                          <p className="mb-0">
                            <span
                              class="badge text-bg-light p-2"
                              data-bs-toggle="modal"
                              data-bs-target="#editSubscriptionModal"
                              style={{ cursor: "pointer" }}
                            >
                              Edit subscription amount{" "}
                              <i class="bi bi-pencil ms-2"></i>
                            </span>
                          </p>
                        </div>
                      )}
                  </div>
                  <div class="d-grid gap-2">
                    <Link
                      class="btn btn-primary rounded-3 p-2 text-white"
                      // type="button"
                      to={`/subscriptions`}
                    >
                      {isAuthenticated &&
                      user &&
                      user.userName === creatorInformation.userName
                        ? "CREATOR"
                        : "SUBSCRIBED"}
                      <i class="bi bi-check ms-2"></i>
                    </Link>
                  </div>
                </div>
                <hr></hr>
              </div>
              {isAuthenticated &&
                user &&
                user.userName === creatorInformation.userName && (
                  <div className="col-md-6 offset-md-3">
                    <div className="px-3">
                      <p class="text-uppercase text-muted">
                        SUBSCRIPTION BUNDLES
                      </p>
                      <div class="vstack gap-3">
                        <div>
                          <Link
                            class="btn btn-primary rounded-3 w-100 p-2 text-white"
                            // type="button"
                            to={`/create-subscription/${creatorInformation.userName}/${subscriptionPlans[1]._id}`}
                          >
                            3 MONTHS (10% off) $
                            {(subscriptionPlans[1].basePrice / 100).toFixed(2)}{" "}
                            total
                          </Link>
                        </div>
                        <div>
                          <Link
                            class="btn btn-primary rounded-3 w-100 p-2 text-white"
                            // type="button"
                            to={`/create-subscription/${creatorInformation.userName}/${subscriptionPlans[2]._id}`}
                          >
                            6 MONTHS (15% off) $
                            {(subscriptionPlans[2].basePrice / 100).toFixed(2)}{" "}
                            total
                          </Link>
                        </div>
                        <div>
                          <Link
                            class="btn btn-primary rounded-3 w-100 p-2 text-white"
                            // type="button"
                            to={`/create-subscription/${creatorInformation.userName}/${subscriptionPlans[3]._id}`}
                          >
                            12 MONTHS (20% off) $
                            {(subscriptionPlans[3].basePrice / 100).toFixed(2)}{" "}
                            total
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
            </>
          )}
          <hr class="border border-secondary border-4 opacity-75"></hr>
          <ul class="nav nav-tabs d-flex justify-content-center">
            <li class="nav-item">
              <button
                className="nav-link link-dark active"
                id="creatorTotalPosts-tab"
                data-bs-toggle="tab"
                data-bs-target="#creatorTotalPosts"
                type="button"
                role="tab"
                onClick={() => setShowCurrent(false)}
              >
                {creatorPosts.length} POSTS
              </button>
            </li>
            <li class="nav-item">
              <button
                className="nav-link link-dark"
                id="creatorMedia-tab"
                data-bs-toggle="tab"
                data-bs-target="#creatorMedia"
                type="button"
                role="tab"
                onClick={() => setShowCurrent(true)}
              >
                {photoPosts.length} MEDIA
              </button>
            </li>
          </ul>
          <div className=""></div>
          <main>
            <div class="album py-5 bg-body-tertiary">
              <div
                className={`tab-pane ${showCurrent ? "d-none" : ""}`}
                id="creatorTotalPosts"
                role="tabpanel"
              >
                <div class="container">
                  <div class="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 g-3">
                    {creatorPosts.map((post) => (
                      <Post
                        creatorInformation={creatorInformation}
                        post={post}
                        key={"mediaPost" + post._id}
                        redirect={`/create-subscription/${creatorInformation.userName}/${subscriptionPlans[0]._id}`}
                        setPostToDelete={setPostToDelete}
                      ></Post>
                    ))}
                  </div>
                </div>
              </div>
              <div
                className={`tab-pane ${showCurrent ? "" : "d-none"}`}
                id="creatorMedia"
                role="tabpanel"
              >
                <div class="container">
                  <div class="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 g-3">
                    {photoPosts.map((post) => (
                      <Post
                        creatorInformation={creatorInformation}
                        post={post}
                        key={"photoPost" + post._id}
                        redirect={`/create-subscription/${creatorInformation.userName}/${subscriptionPlans[0]._id}`}
                        setPostToDelete={setPostToDelete}
                      ></Post>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </main>
        </>
      )}
      {error && <NotFoundScreen></NotFoundScreen>}
    </>
  );
};

export default ProfileScreen;

import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactImageGallery from "./ReactImageGallery";
import CloudinaryVideoPlayer from "./CloudinaryVideoPlayer";
import { LIKE_POST_FAIL } from "../constants/postConstants";
import { likePost } from "../actions/postActions";
import { Link } from "react-router-dom";

const Post = ({ creatorInformation, post, redirect, setPostToDelete }) => {
  const dispatch = useDispatch();

  const [likedPhoto, setLikedPhoto] = useState(post.hasLiked);
  const [addedLike, setAddedLike] = useState(0);
  const userLogin = useSelector((state) => state.userLogin);
  const { isAuthenticated, user } = userLogin;

  const handleLike = () => {
    if (isAuthenticated) {
      setLikedPhoto(true);
      dispatch(likePost(post._id));
      setAddedLike(1);
    } else {
      dispatch({
        type: LIKE_POST_FAIL,
        payload: "You must be logged in to like a post",
      });
    }
  };
  return (
    <div class="col">
      <div class="card shadow-sm">
        <div className="bg-light py-5 px-3 text-center position-relative">
          <div class="position-absolute top-0 start-0 ms-2 mt-2">
            {post.contentType === "Photo" ? (
              <i class="bi bi-image"></i>
            ) : (
              <i class="bi bi-camera-video"></i>
            )}
            &nbsp;1 &#183; {post.likes.length + addedLike} likes
          </div>
          <div class="position-absolute top-0 end-0 mt-2 me-2">
            {/* <i class="bi bi-bookmark"></i> */}
            <div className="d-flex flex-row">
              <div>
                {isAuthenticated && (
                  <>
                    {!post.content ? (
                      <></>
                    ) : likedPhoto ? (
                      <i class="bi bi-heart-fill"></i>
                    ) : (
                      <i
                        class="bi bi-heart"
                        onClick={() => handleLike()}
                        style={{ cursor: "pointer" }}
                      ></i>
                    )}
                  </>
                )}
              </div>
              <div>
                {isAuthenticated &&
                  user.userName === creatorInformation.userName && (
                    // <i class="bi bi-three-dots-horizontal"></i>
                    <i
                      class="bi bi-three-dots ms-3"
                      data-bs-toggle="modal"
                      data-bs-target="#deletePostModal"
                      style={{ cursor: "pointer" }}
                      onClick={() => setPostToDelete(post)}
                    ></i>
                  )}
              </div>
            </div>
          </div>

          {post.content ? (
            <div className="d-flex justify-content-center">
              {post.contentType === "Photo" ? (
                // <img
                //   src={post.content}
                //   style={{ width: 304, height: 171 }}
                //   class="img-fluid"
                //   alt=""
                //   // style={{ width: 75, height: 75, marginLeft: 100 }}
                // ></img>
                <ReactImageGallery
                  imageUrls={[post.content]}
                ></ReactImageGallery>
              ) : (
                // <ReactImageGallery
                //   imageUrls={[post.content]}
                // ></ReactImageGallery>
                <CloudinaryVideoPlayer
                  url={post.content}
                ></CloudinaryVideoPlayer>
              )}
            </div>
          ) : (
            // <img
            //   src={post.content}
            //   class="img-fluid"
            //   alt=""
            //   // style={{ width: 75, height: 75, marginLeft: 100 }}
            // ></img>
            <>
              <h1 className="text-muted">
                <i class="bi bi-lock"></i>
              </h1>
              <div className="p-3 py-4 border">
                <Link
                  class="btn btn-primary rounded-3 w-100 text-white"
                  // type="button"
                  to={redirect}
                >
                  SUBSCRIBE TO SEE USERS POSTS
                </Link>
              </div>
            </>
          )}
        </div>
        <div class="card-body">
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex flex-row">
              <div className="position-relative">
                <img
                  src={creatorInformation.profilePhoto}
                  class="bd-placeholder-img rounded-circle border-white"
                  alt=""
                  style={{ width: 50, height: 50 }}
                ></img>
              </div>
              <div class="container">
                <h6>{creatorInformation.userName}</h6>
                {/* {isAuthenticated &&
                  user.userName === creatorInformation.userName && (
                    <h6>
                      <span
                        class="badge text-bg-light p-2"
                        data-bs-toggle="modal"
                        data-bs-target="#editProfileModal"
                        style={{ cursor: "pointer" }}
                      >
                        Edit profile <i class="bi bi-pencil ms-2"></i>
                      </span>
                    </h6>
                  )} */}
              </div>
            </div>
            <small class="text-body-secondary">{post.createdAt}</small>
          </div>

          <p class="card-text mt-2">{post.caption}</p>
        </div>
      </div>
    </div>
  );
};

export default Post;

import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./css/main.min.css";
// import "../node_modules/bootstrap/dist/js/bootstrap.bundle.js";

import Navbar from "./components/Navbar.js";
import Footer from "./components/Footer.js";
import MainScreen from "./screens/MainScreen.js";
import SignUpScreen from "./screens/SignUpScreen.js";
import LogInScreen from "./screens/LogInScreen.js";
import DashboardScreen from "./screens/DashboardScreen.js";
import ProfileScreen from "./screens/ProfileScreen.js";
import SubscriptionScreen from "./screens/SubscriptionScreen.js";
import AlertsContainer from "./components/AlertsContainer.js";
import CreateSubscriptionScreen from "./screens/CreateSubscriptionScreen.js";
import NotFoundScreen from "./screens/NotFoundScreen.js";
import LoginErrorScreen from "./screens/LoginErrorScreen.js";
import ChatsScreen from "./screens/ChatsScreen.js";
import ChatDetailsScreen from "./screens/ChatDetailsScreen.js";
import SendTipScreen from "./screens/SendTipScreen.js";
import TermsScreen from "./screens/TermsScreen.js";
import PrivacyPolicyScreen from "./screens/PrivacyPolicyScreen.js";
import FAQScreen from "./screens/FAQScreen.js";

function App() {
  return (
    <>
      <BrowserRouter>
        <Navbar></Navbar>

        <AlertsContainer></AlertsContainer>
        <Routes>
          <Route path="/" element={<MainScreen />} />
          <Route path="/signup" element={<SignUpScreen />} />
          <Route path="/login" element={<LogInScreen />} />
          <Route path="/dashboard/:userName" element={<DashboardScreen />} />
          <Route path="/:userName" element={<ProfileScreen />} />
          <Route path="/subscriptions" element={<SubscriptionScreen />} />
          <Route
            path="/create-subscription/:creatorUsername/:priceId"
            element={<CreateSubscriptionScreen />}
          />
          <Route
            path="/send-creator-tip/:creatorUsername"
            element={<SendTipScreen />}
          />
          <Route path="/chats" element={<ChatsScreen />} />
          <Route path="/chats/:chatId" element={<ChatDetailsScreen />} />
          <Route path="/terms" element={<TermsScreen />} />
          <Route path="/privacy" element={<PrivacyPolicyScreen />} />
          <Route path="/faq" element={<FAQScreen />} />
          <Route path="/oautherror" element={<LoginErrorScreen />} />
          <Route path="/*" element={<NotFoundScreen></NotFoundScreen>} />
        </Routes>
        {/* <Footer></Footer> */}
      </BrowserRouter>

      {/* <Uploader></Uploader> */}
      {/* <ReactDropzone></ReactDropzone> */}
      {/* <ReactImageUpload></ReactImageUpload> */}
    </>
  );
}

export default App;

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/analytics";

var firebaseConfig;

if (window.location.hostname === "solostreamer.com") {
  // Initialize Firebase Datastore for production
  firebaseConfig = {
    apiKey: "AIzaSyAz0EwUTuJXERUkcIFY1cPc1H-4YmQr8kQ",
    authDomain: "solostreamer.firebaseapp.com",
    projectId: "solostreamer",
    storageBucket: "solostreamer.appspot.com",
    messagingSenderId: "649546105756",
    appId: "1:649546105756:web:ae958bf873851e5b9b7e59",
    measurementId: "G-RV1MRXNLLJ",
  };
} else {
  // Initialize Firebase Datastore for testing
  firebaseConfig = {
    apiKey: "AIzaSyAUEldiruvGsTRdakQDfonYJQBXZuEQgkg",
    authDomain: "solostreamer-test.firebaseapp.com",
    projectId: "solostreamer-test",
    storageBucket: "solostreamer-test.appspot.com",
    messagingSenderId: "32608479875",
    appId: "1:32608479875:web:e3c88aa278c7db47da89b6",
    measurementId: "G-15TCZ9VNEP",
  };
}

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  if ("measurementId" in firebaseConfig) {
    firebase.analytics();
  }
}

const auth = firebase.auth();
const firestore = firebase.firestore();
const analytics = firebase.analytics();

export { auth, firestore, firebase };

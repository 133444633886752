import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  cancelUserSubscription,
  getUserSubscriptionScreenInformation,
} from "../actions/subscriptionActions";

const SubscriptionScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  // Create a URLSearchParams object from the query string
  const queryParams = new URLSearchParams(location.search);

  // Get the 'choice' parameter from the URL
  const choice = queryParams.get("choice");

  const [subscribers, setSubscribers] = useState(false);
  const [showCurrent, setShowCurrent] = useState(false);
  const [toggleView, setToggleView] = useState(
    choice ? choice : "MY SUBSCRIPTIONS"
  );

  const [subscriptionToDelete, setSubscriptionToDelete] = useState(null);
  const userLogin = useSelector((state) => state.userLogin);
  const { isAuthenticated, loading: userLoading } = userLogin;
  const subscriptionScreenInformation = useSelector(
    (state) => state.subscriptionScreenInformation
  );
  const {
    activeSubscriptions,
    cancelledSubscriptions,
    loading: subscriptionsLoading,
    sentTips,
    error,
  } = subscriptionScreenInformation;

  const closeModal = () => {
    let modal = document.getElementById(
      "cancelUserSubscriptionModalCloseButton"
    );
    modal.click();
  };

  const handleCancelSubscription = async () => {
    if (!subscriptionToDelete) return;
    await dispatch(
      cancelUserSubscription(
        subscriptionToDelete.subscriptionId,
        "User cancelled subscription"
      )
    );
    closeModal();
    dispatch(getUserSubscriptionScreenInformation());
  };

  useEffect(() => {
    if (isAuthenticated === false) {
      navigate(`/login?redirect=${location.pathname}`);
    }
    if (isAuthenticated === true) {
      dispatch(getUserSubscriptionScreenInformation());
    }
  }, [isAuthenticated]);

  return (
    <>
      <div className="container justify-content-center col-lg-9 mt-4">
        {/* <h6 className="text-muted mt-5">MY SUBSCRIPTIONS </h6> */}
        <div className="dropdown mt-5 mb-3">
          <Link
            to="#"
            className="text-decoration-none text-reset align-middle"
            data-bs-toggle="dropdown"
          >
            <h6 className="text-muted dropdown-toggle d-inline">
              {toggleView}
            </h6>
          </Link>
          <ul className="dropdown-menu text-small shadow">
            <li>
              <button
                className="dropdown-item"
                onClick={() => setToggleView("MY SUBSCRIPTIONS")}
              >
                MY SUBSCRIPTIONS
              </button>
            </li>
            <li>
              <button
                className="dropdown-item"
                onClick={() => setToggleView("SENT TIPS")}
              >
                SENT TIPS
              </button>
            </li>
          </ul>
        </div>

        {(userLoading || subscriptionsLoading) && (
          <div class="my-3">
            <div class="vstack gap-3 px-0">
              <div className="border rounded p-2">
                <p class="placeholder-glow mb-1">
                  <span class="placeholder col-4"></span>
                  <span class="placeholder col-9"></span>
                  <span class="placeholder col-12"></span>
                </p>
              </div>
              <div className="border rounded p-2">
                <p class="placeholder-glow mb-1">
                  <span class="placeholder col-4"></span>
                  <span class="placeholder col-9"></span>
                  <span class="placeholder col-12"></span>
                </p>
              </div>
              <div className="border rounded p-2">
                <p class="placeholder-glow mb-1">
                  <span class="placeholder col-4"></span>
                  <span class="placeholder col-9"></span>
                  <span class="placeholder col-12"></span>
                </p>
              </div>
              <div className="border rounded p-2">
                <p class="placeholder-glow mb-1">
                  <span class="placeholder col-4"></span>
                  <span class="placeholder col-9"></span>
                  <span class="placeholder col-12"></span>
                </p>
              </div>
              <div className="border rounded p-2">
                <p class="placeholder-glow mb-1">
                  <span class="placeholder col-4"></span>
                  <span class="placeholder col-9"></span>
                  <span class="placeholder col-12"></span>
                </p>
              </div>
            </div>
          </div>
        )}
        {!userLoading && !subscriptionsLoading && !error && (
          <>
            {toggleView === "MY SUBSCRIPTIONS" && (
              <>
                <ul class="nav nav-tabs d-flex justify-content-center justify-content-md-end">
                  <li class="nav-item">
                    <button
                      className="nav-link link-dark active"
                      id="userActiveSubscriptions-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#userActiveSubscriptions"
                      type="button"
                      role="tab"
                      onClick={() => setShowCurrent(false)}
                    >
                      ACTIVE
                    </button>
                  </li>
                  <li class="nav-item">
                    <button
                      className="nav-link link-dark"
                      id="userCancelledSubscriptions-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#userCancelledSubscriptions"
                      type="button"
                      role="tab"
                      onClick={() => setShowCurrent(true)}
                    >
                      CANCELLED
                    </button>
                  </li>
                </ul>
                <main>
                  <div class="album ">
                    <div
                      className={`tab-pane ${showCurrent ? "d-none" : ""}`}
                      id="userActiveSubscriptions"
                      role="tabpanel"
                    >
                      <div class="my-3">
                        {activeSubscriptions.length === 0 && (
                          <div class="p-5 text-center bg-body-tertiary">
                            <div class="container py-5">
                              <h1 class="text-body-emphasis">
                                <i class="bi bi-people-fill"></i>
                              </h1>
                              <h1 class="text-body-emphasis">
                                No subscriptions yet
                              </h1>
                              <p class="col-lg-8 mx-auto lead">
                                Once you are subscribed to a creator they will
                                show up here
                              </p>
                            </div>
                          </div>
                        )}

                        <div class="vstack gap-3 px-0">
                          {activeSubscriptions.map((subscription) => (
                            <div
                              class="container border rounded shadow-sm p-3"
                              key={"userSubscription" + Math.random()}
                            >
                              <div class="hstack gap-3">
                                <div>
                                  <img
                                    className="img-fluid rounded-circle"
                                    style={{ width: 48 }}
                                    src={subscription.creator.profilePhoto}
                                  ></img>
                                </div>
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    navigate(
                                      `/${subscription.creator.userName}`
                                    )
                                  }
                                >
                                  <h6 class="mb-0">
                                    @{subscription.creator.userName}
                                  </h6>
                                  <p class="text-body-secondary fw-light mb-1">
                                    {`${subscription.subscriptionPlan.name}, started: ${subscription.formattedCreatedAt}`}
                                  </p>
                                  {subscription.formattedCancelledDate && (
                                    <p className="mb-0">
                                      <small>
                                        Cancelled:{" "}
                                        {subscription.formattedCancelledDate}
                                      </small>
                                    </p>
                                  )}
                                  {subscription.formattedEndDate && (
                                    <p className="mb-0">
                                      <small>
                                        Active until:{" "}
                                        {subscription.formattedEndDate}
                                      </small>
                                    </p>
                                  )}
                                  {subscription.cancellationReason && (
                                    <p className="mb-0">
                                      <small>
                                        Cancellation reason:{" "}
                                        {subscription.cancellationReason}
                                      </small>
                                    </p>
                                  )}
                                </div>

                                <div className="ms-auto d-none d-md-block">
                                  {subscription.endDate === null && (
                                    <a
                                      href=""
                                      class="link-primary text-decoration-none"
                                      data-bs-toggle="modal"
                                      data-bs-target="#cancelSubscriptionModal"
                                      onClick={() =>
                                        setSubscriptionToDelete(subscription)
                                      }
                                    >
                                      Cancel
                                    </a>
                                  )}
                                </div>
                              </div>
                              <div className="ms-auto d-md-none">
                                {subscription.endDate === null && (
                                  <a
                                    href=""
                                    class="link-primary text-decoration-none"
                                    data-bs-toggle="modal"
                                    data-bs-target="#cancelSubscriptionModal"
                                    onClick={() =>
                                      setSubscriptionToDelete(subscription)
                                    }
                                  >
                                    Cancel
                                  </a>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div
                      className={`tab-pane ${showCurrent ? "" : "d-none"}`}
                      id="userCancelledSubscriptions"
                      role="tabpanel"
                    >
                      <div class="my-3">
                        {cancelledSubscriptions.length === 0 && (
                          <div class="p-5 text-center bg-body-tertiary">
                            <div class="container py-5">
                              <h1 class="text-body-emphasis">
                                <i class="bi bi-people-fill"></i>
                              </h1>
                              <h1 class="text-body-emphasis">
                                No subscriptions yet
                              </h1>
                              <p class="col-lg-8 mx-auto lead">
                                Once you are subscribed to a creator they will
                                show up here
                              </p>
                            </div>
                          </div>
                        )}

                        <div class="vstack gap-3 px-0">
                          {cancelledSubscriptions.map((subscription) => (
                            <div
                              class="container border rounded shadow-sm p-3"
                              key={"userSubscription" + Math.random()}
                            >
                              <div class="hstack gap-3">
                                <div>
                                  <img
                                    className="img-fluid rounded-circle"
                                    style={{ width: 48 }}
                                    src={subscription.creator.profilePhoto}
                                  ></img>
                                </div>
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    navigate(
                                      `/${subscription.creator.userName}`
                                    )
                                  }
                                >
                                  <h6 class="mb-0">
                                    @{subscription.creator.userName}
                                  </h6>
                                  <p class="text-body-secondary fw-light mb-1">
                                    {`${subscription.subscriptionPlan.name}, started: ${subscription.formattedCreatedAt}`}
                                  </p>
                                  {subscription.formattedCancelledDate && (
                                    <p className="mb-0">
                                      <small>
                                        Cancelled:{" "}
                                        {subscription.formattedCancelledDate}
                                      </small>
                                    </p>
                                  )}
                                  {subscription.formattedEndDate && (
                                    <p className="mb-0">
                                      <small>
                                        Active until:{" "}
                                        {subscription.formattedEndDate}
                                      </small>
                                    </p>
                                  )}
                                  {subscription.cancellationReason && (
                                    <p className="mb-0">
                                      <small>
                                        Cancellation reason:{" "}
                                        {subscription.cancellationReason}
                                      </small>
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </main>
              </>
            )}
            {toggleView === "SENT TIPS" && (
              <div class="my-3">
                {sentTips.length === 0 && (
                  <div class="p-5 text-center bg-body-tertiary">
                    <div class="container py-5">
                      <h1 class="text-body-emphasis">
                        <i class="bi bi-cash-stack"></i>
                      </h1>
                      <h1 class="text-body-emphasis">No tips sent</h1>
                      <p class="col-lg-8 mx-auto lead">
                        Once you have tipped a creator they will show up here.
                      </p>
                    </div>
                  </div>
                )}

                <div class="vstack gap-3 px-0">
                  {sentTips.map((tip) => (
                    <div
                      class="container border rounded shadow-sm p-3"
                      key={"userSubscription" + Math.random()}
                    >
                      <div class="hstack gap-3">
                        <div className="d-none d-md-block">
                          <img
                            className="img-fluid rounded-circle"
                            style={{ width: 48 }}
                            src={tip.creator.profilePhoto}
                          ></img>
                        </div>
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => navigate(`/${tip.creator.userName}`)}
                        >
                          <img
                            className="img-fluid rounded-circle d-md-none mb-2"
                            style={{ width: 48 }}
                            src={tip.creator.profilePhoto}
                          ></img>
                          <h6 class="mb-1">Creator: @{tip.creator.userName}</h6>
                          <p class="text-body-secondary fw-light mb-0">
                            Amount: ${(tip.amount / 100).toFixed(2)}
                          </p>
                          <p className="mb-0">
                            <small>Sent: {tip.formattedCreatedAt}</small>
                          </p>

                          <p className="mb-0">
                            <small>Message: {tip.message}</small>
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <div
        class="modal fade"
        id="cancelSubscriptionModal"
        tabIndex="-1"
        aria-labelledby="cancelSubscriptionModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="cancelSubscriptionModalLabel">
                Edit Subscription
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="cancelUserSubscriptionModalCloseButton"
              ></button>
            </div>
            <div class="modal-body p-4">
              <h6 className="text-muted">SUBSCRIPTION INFO</h6>
              {subscriptionToDelete && (
                <li class="list-group-item list-group-item-action d-flex gap-3 py-3">
                  <img
                    src={subscriptionToDelete.creator.profilePhoto}
                    alt="twbs"
                    width="32"
                    height="32"
                    class="rounded-circle flex-shrink-0"
                  ></img>
                  <div class="d-flex gap-2 w-100 justify-content-between">
                    <div>
                      <h6 class="mb-0">
                        @{subscriptionToDelete.creator.userName}
                      </h6>
                      <p class="mb-1 opacity-75">
                        {subscriptionToDelete.subscriptionPlan.name}
                      </p>
                    </div>
                    <small class="opacity-50 text-nowrap">
                      Started: {subscriptionToDelete.formattedCreatedAt}
                    </small>
                  </div>
                </li>
              )}
              <h5 class="mb-0">Cancel this subscription?</h5>
              <p class="mb-0">
                This will cancel your subscription to this user. Your
                subscription will last until your most recent payment
              </p>
            </div>
            <div class="modal-footer flex-nowrap p-0">
              <button
                onClick={() => handleCancelSubscription()}
                type="button"
                class="btn btn-lg btn-primary fs-6 text-decoration-none col-6 py-3 m-0 rounded-0 border-end"
              >
                <strong>Cancel subscription</strong>
              </button>
              <button
                type="button"
                class="btn btn-lg btn-primary fs-6 text-decoration-none col-6 py-3 m-0 rounded-0"
                data-bs-dismiss="modal"
              >
                No thanks
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriptionScreen;

export const GET_SUBSCRIPTION_SCREEN_INFO_REQUEST =
  "GET_SUBSCRIPTION_SCREEN_INFO_REQUEST";
export const GET_SUBSCRIPTION_SCREEN_INFO_SUCCESS =
  "GET_SUBSCRIPTION_SCREEN_INFO_SUCCESS";
export const GET_SUBSCRIPTION_SCREEN_INFO_FAIL =
  "GET_SUBSCRIPTION_SCREEN_INFO_FAIL";
export const GET_USER_SUBSCRIPTION_SCREEN_INFO_REQUEST =
  "GET_USER_SUBSCRIPTION_SCREEN_INFO_REQUEST";
export const GET_USER_SUBSCRIPTION_SCREEN_INFO_SUCCESS =
  "GET_USER_SUBSCRIPTION_SCREEN_INFO_SUCCESS";
export const GET_USER_SUBSCRIPTION_SCREEN_INFO_FAIL =
  "GET_USER_SUBSCRIPTION_SCREEN_INFO_FAIL";
export const CANCEL_USER_SUBSCRIPTION_REQUEST =
  "CANCEL_USER_SUBSCRIPTION_REQUEST";
export const CANCEL_USER_SUBSCRIPTION_SUCCESS =
  "CANCEL_USER_SUBSCRIPTION_SUCCESS";
export const CANCEL_USER_SUBSCRIPTION_FAIL = "CANCEL_USER_SUBSCRIPTION_FAIL";

import React, { useState, useEffect } from "react";
import axios from "axios";
import PayoutCreatorModal from "../components/PayoutCreatorModal";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCreatorDashboardInformation } from "../actions/userActions";
import { findOrCreateChat } from "../utilities/chatService";

const DashboardScreen = () => {
  const params = useParams();
  const navigate = useNavigate();
  const userName = params.userName;
  const dispatch = useDispatch();
  const [redirectLoading, setRedirectLoading] = useState(false);

  const [showCurrent, setShowCurrent] = useState(false);
  const [toggleView, setToggleView] = useState("SUBSCRIBERS");

  const userLogin = useSelector((state) => state.userLogin);
  const { isAuthenticated, user, isCreator, loading: loginLoading } = userLogin;

  const creatorDashboardInformation = useSelector(
    (state) => state.creatorDashboardInformation
  );
  const {
    creatorInformation,
    activeSubscriptions,
    cancelledSubscriptions,
    balancePending,
    balanceAvailable,
    receivedTips,
    loading: dashboardLoading,
    error,
  } = creatorDashboardInformation;

  const redirectToStripeLogin = async () => {
    try {
      setRedirectLoading(true);
      const { data } = await axios.get("/api/users/stripe-login-link/");
      // window.open(data, "_blank");
      // To open in current window
      window.location.href = data;
      setRedirectLoading(false);
    } catch (error) {
      setRedirectLoading(false);
      console.log(error);
    }
  };
  const createOrFindChatWithSubscriber = async (subscriberInformation) => {
    if (!user || !subscriberInformation) {
      return;
    }
    try {
      await findOrCreateChat(subscriberInformation, user, navigate);
    } catch (error) {
      alert("Unable to create chat");
    }
  };
  useEffect(() => {
    if (isAuthenticated === true) {
      if (user.userName === userName) {
        dispatch(getCreatorDashboardInformation(userName));
      } else {
        navigate("/login");
      }
    }
    if (isAuthenticated === false) {
      navigate("/login");
    }
  }, [userName, isAuthenticated]);
  return (
    <>
      {(loginLoading || dashboardLoading) && (
        <>
          <div className="row mx-3 justify-content-center">
            <div className="col-lg-8 mt-0 p-2">
              <div class="card mx-1">
                <div class="card-body">
                  <h5 class="card-title placeholder-glow">
                    <span class="placeholder col-6"></span>
                  </h5>
                  <p class="card-text placeholder-glow">
                    <span class="placeholder col-7"></span>
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-6"></span>
                    <span class="placeholder col-8"></span>
                  </p>
                  <a
                    class="btn btn-primary disabled placeholder col-6"
                    aria-disabled="true"
                  ></a>
                </div>
              </div>
            </div>
          </div>
          <div className="row mx-3 justify-content-center">
            <div className="col-lg-8">
              <div class="my-3">
                <div class="vstack gap-3 px-0">
                  <div className="border rounded p-2">
                    <p class="placeholder-glow mb-1">
                      <span class="placeholder col-4"></span>
                      <span class="placeholder col-9"></span>
                      <span class="placeholder col-12"></span>
                    </p>
                  </div>
                  <div className="border rounded p-2">
                    <p class="placeholder-glow mb-1">
                      <span class="placeholder col-4"></span>
                      <span class="placeholder col-9"></span>
                      <span class="placeholder col-12"></span>
                    </p>
                  </div>
                  <div className="border rounded p-2">
                    <p class="placeholder-glow mb-1">
                      <span class="placeholder col-4"></span>
                      <span class="placeholder col-9"></span>
                      <span class="placeholder col-12"></span>
                    </p>
                  </div>
                  <div className="border rounded p-2">
                    <p class="placeholder-glow mb-1">
                      <span class="placeholder col-4"></span>
                      <span class="placeholder col-9"></span>
                      <span class="placeholder col-12"></span>
                    </p>
                  </div>
                  <div className="border rounded p-2">
                    <p class="placeholder-glow mb-1">
                      <span class="placeholder col-4"></span>
                      <span class="placeholder col-9"></span>
                      <span class="placeholder col-12"></span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {!dashboardLoading && !error && creatorInformation && (
        <div className="container justify-content-center col-lg-9 mt-4">
          <PayoutCreatorModal></PayoutCreatorModal>
          <div className="row g-3">
            <div class="col-12 col-md-6">
              <div className="d-flex align-items-center">
                <div className="me-2">
                  <img
                    src={creatorInformation.profilePhoto}
                    class="bd-placeholder-img rounded-circle border-white"
                    alt=""
                    style={{ width: 50, height: 50 }}
                  ></img>
                </div>
                <div className="flex-column ms-2">
                  <p className="mb-0">
                    <small>
                      Creator since {creatorInformation.formattedDate}
                    </small>
                  </p>
                  <h5 className=" mb-0">
                    {creatorInformation.firstName} {creatorInformation.lastName}
                  </h5>
                  <Link
                    className=" mb-0 text-decoration-none"
                    to={`/${creatorInformation.userName}`}
                  >
                    <small className="text-primary">
                      View your page @{creatorInformation.userName}
                    </small>
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div className="row">
                <div className="col-6">
                  <div className="flex-column">
                    <p className="mb-0">
                      <small>Subscription plan</small>
                    </p>
                    <h5 className=" mb-0">
                      {creatorInformation.creatorSubscriptionType}
                    </h5>
                    <p className=" mb-0">
                      <small className="text-muted">
                        {activeSubscriptions.length} subscribers
                      </small>
                    </p>
                  </div>
                </div>
                <div className="col-6">
                  <div className="flex-column">
                    <p className="mb-0">
                      <small>Your balance</small>
                    </p>
                    <h5 className=" mb-0">
                      ${(balanceAvailable / 100).toFixed(2)}
                    </h5>
                    <p className=" mb-0">
                      <small className="text-muted">
                        ${(balancePending / 100).toFixed(2)} pending
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-2">
              <div className="flex-column">
                <div class="d-grid gap-2">
                  <button
                    class="btn btn-primary text-white"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#payoutCreatorModal"
                    disabled={balanceAvailable < 300}
                  >
                    Pay out now
                  </button>
                </div>
                <p
                  className="mb-0 mt-1 text-center"
                  style={{ cursor: "pointer" }}
                >
                  <small
                    className="text-primary"
                    onClick={() => redirectToStripeLogin()}
                  >
                    View payouts on Stripe{" "}
                    {redirectLoading && (
                      <div
                        class="spinner-border text-primary spinner-border-sm ms-2"
                        role="status"
                      >
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </small>
                </p>
              </div>
            </div>
          </div>
          <div className="dropdown mt-5 mb-3">
            <Link
              to="#"
              className="text-decoration-none text-reset align-middle"
              data-bs-toggle="dropdown"
            >
              <h6 className="text-muted dropdown-toggle d-inline">
                {toggleView}
              </h6>
            </Link>
            <ul className="dropdown-menu text-small shadow">
              <li>
                <button
                  className="dropdown-item"
                  onClick={() => setToggleView("SUBSCRIBERS")}
                >
                  MY SUBSCRIBERS
                </button>
              </li>
              <li>
                <button
                  className="dropdown-item"
                  onClick={() => setToggleView("TIPS")}
                >
                  RECIEVED TIPS
                </button>
              </li>
            </ul>
          </div>

          {toggleView === "SUBSCRIBERS" && (
            <>
              <ul class="nav nav-tabs d-flex justify-content-center justify-content-md-end">
                <li class="nav-item">
                  <button
                    className="nav-link link-dark active"
                    id="creatorActiveSubscribers-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#creatorActiveSubscribers"
                    type="button"
                    role="tab"
                    onClick={() => setShowCurrent(false)}
                  >
                    ACTIVE
                  </button>
                </li>
                <li class="nav-item">
                  <button
                    className="nav-link link-dark"
                    id="creatorPastSubscribers-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#creatorPastSubscribers"
                    type="button"
                    role="tab"
                    onClick={() => setShowCurrent(true)}
                  >
                    PAST
                  </button>
                </li>
              </ul>
              <main>
                <div class="album ">
                  <div
                    className={`tab-pane ${showCurrent ? "d-none" : ""}`}
                    id="creatorActiveSubscribers"
                    role="tabpanel"
                  >
                    <div class="my-3">
                      {activeSubscriptions.length == 0 && (
                        <div class="p-5 text-center bg-body-tertiary">
                          <div class="container py-5">
                            <h1 class="text-body-emphasis">
                              <i class="bi bi-people-fill"></i>
                            </h1>
                            <h1 class="text-body-emphasis">
                              No active subscribers yet
                            </h1>
                            <p class="col-lg-8 mx-auto lead">
                              Once you have some fans they will show up here
                            </p>
                            <Link
                              class="btn btn-primary text-white"
                              to={`/${creatorInformation.userName}`}
                            >
                              View or edit your page
                            </Link>
                          </div>
                        </div>
                      )}
                      {activeSubscriptions.length > 0 && (
                        <div class="list-group">
                          {activeSubscriptions.map((subscription, index) => (
                            <li
                              class="list-group-item list-group-item-action d-flex gap-3 py-3"
                              key={"activeCreatorSubscriptions" + index}
                              onClick={() =>
                                createOrFindChatWithSubscriber(
                                  subscription.subscriber[0]
                                )
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <img
                                src={subscription.subscriber[0].profilePhoto}
                                alt="twbs"
                                width="32"
                                height="32"
                                class="rounded-circle flex-shrink-0"
                              ></img>
                              <div class="d-flex gap-2 w-100 justify-content-between">
                                <div>
                                  <h6 class="mb-0">
                                    {subscription.subscriber[0].firstName}{" "}
                                    {subscription.subscriber[0].lastName
                                      .charAt(0)
                                      .toUpperCase() ?? ""}
                                    .
                                  </h6>
                                  <p class="mb-0 opacity-75">
                                    {subscription.pricePlan[0].name}
                                  </p>
                                  {subscription.formattedEndDate && (
                                    <p class="mb-0">
                                      <small>
                                        Will end:{" "}
                                        {subscription.formattedEndDate}
                                      </small>
                                    </p>
                                  )}
                                </div>
                                <small class="opacity-50 text-nowrap">
                                  Started: {subscription.formattedCreatedAt}
                                </small>
                              </div>
                            </li>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className={`tab-pane ${showCurrent ? "" : "d-none"}`}
                    id="creatorPastSubscribers"
                    role="tabpanel"
                  >
                    <div class="my-3">
                      {cancelledSubscriptions.length == 0 && (
                        <div class="p-5 text-center bg-body-tertiary">
                          <div class="container py-5">
                            <h1 class="text-body-emphasis">
                              <i class="bi bi-people-fill"></i>
                            </h1>
                            <h1 class="text-body-emphasis">
                              No cancelled subscribers! Great job!
                            </h1>
                            <p class="col-lg-8 mx-auto lead">
                              Once a fan not longer subscribes to you they will
                              show up here.
                            </p>
                            <Link
                              class="btn btn-primary text-white"
                              to={`/${creatorInformation.userName}`}
                            >
                              View or edit your page
                            </Link>
                          </div>
                        </div>
                      )}
                      {cancelledSubscriptions.length > 0 && (
                        <div class="list-group">
                          {cancelledSubscriptions.map((subscription, index) => (
                            <li
                              class="list-group-item list-group-item-action d-flex gap-3 py-3"
                              key={"cancelledCreatorSubscriptions" + index}
                              onClick={() =>
                                createOrFindChatWithSubscriber(
                                  subscription.subscriber[0]
                                )
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <img
                                src={subscription.subscriber[0].profilePhoto}
                                alt="twbs"
                                width="32"
                                height="32"
                                class="rounded-circle flex-shrink-0"
                              ></img>
                              <div class="d-flex gap-2 w-100 justify-content-between">
                                <div>
                                  <h6 class="mb-0">
                                    {subscription.subscriber[0].firstName}{" "}
                                    {subscription.subscriber[0].lastName
                                      .charAt(0)
                                      .toUpperCase() ?? ""}
                                    .
                                  </h6>
                                  <p class="mb-0 opacity-75">
                                    {subscription.pricePlan[0].name}
                                  </p>
                                  {subscription.formattedCancelledDate && (
                                    <p class="mb-0">
                                      <small>
                                        Cancelled:{" "}
                                        {subscription.formattedCancelledDate}
                                      </small>
                                    </p>
                                  )}
                                  {subscription.formattedEndDate && (
                                    <p class="mb-0">
                                      <small>
                                        Will end:{" "}
                                        {subscription.formattedEndDate}
                                      </small>
                                    </p>
                                  )}
                                </div>
                                <small class="opacity-50 text-nowrap">
                                  Started: {subscription.formattedCreatedAt}
                                </small>
                              </div>
                            </li>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </main>
            </>
          )}
          {toggleView === "TIPS" && (
            <div class="my-3">
              {receivedTips.length === 0 && (
                <div class="p-5 text-center bg-body-tertiary">
                  <div class="container py-5">
                    <h1 class="text-body-emphasis">
                      <i class="bi bi-cash-stack"></i>
                    </h1>
                    <h1 class="text-body-emphasis">No tips receieved</h1>
                    <p class="col-lg-8 mx-auto lead">
                      Once you have been sent a tip by a subscriber they will
                      show up here.
                    </p>
                  </div>
                </div>
              )}

              <div class="vstack gap-3 px-0">
                {receivedTips.map((tip) => (
                  <div
                    class="container border rounded shadow-sm p-3"
                    key={"creatorTips" + Math.random()}
                    onClick={() =>
                      createOrFindChatWithSubscriber(tip.subscriber)
                    }
                  >
                    <div class="hstack gap-3">
                      <div className="d-none d-md-block">
                        <img
                          className="img-fluid rounded-circle"
                          style={{ width: 48 }}
                          src={tip.subscriber.profilePhoto}
                        ></img>
                      </div>
                      <div
                        style={{ cursor: "pointer" }}
                        // onClick={() => navigate(`/${tip.subscriber.userName}`)}
                      >
                        <img
                          className="img-fluid rounded-circle d-md-none mb-2"
                          style={{ width: 48 }}
                          src={tip.subscriber.profilePhoto}
                        ></img>
                        <h6 class="mb-1">
                          Subscriber: {tip.subscriber.firstName}{" "}
                          {tip.subscriber?.lastName?.charAt(0).toUpperCase() ??
                            ""}
                          .
                        </h6>
                        <p class="text-body-secondary fw-light mb-0">
                          Amount: ${(tip.amount / 100).toFixed(2)}
                        </p>
                        <p className="mb-0">
                          <small>
                            Payment processing (2.9% + 30¢): $
                            {(tip.stripeFee / 100).toFixed(2)}
                          </small>
                        </p>
                        <p className="mb-0">
                          <small>
                            SoloStreamer Fee (10%): $
                            {(tip.soloStreamerFee / 100).toFixed(2)}
                          </small>
                        </p>
                        <p className="mb-0">
                          <small>
                            Net: ${(tip.netAmount / 100).toFixed(2)}
                          </small>
                        </p>
                        <p className="mb-0">
                          <small>Received: {tip.formattedCreatedAt}</small>
                        </p>

                        <p className="mb-0">
                          <small>Message: {tip.message}</small>
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default DashboardScreen;

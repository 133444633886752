import React from "react";
import ImageGallery from "react-image-gallery";
import { extractPublicId } from "cloudinary-build-url";

const ReactImageGallery = ({ imageUrls }) => {
  // const transformedImages = imageUrls.map((imageUrl) => {
  //     const publicId = extractPublicId(imageUrl);
  //     const transformedImageUrl = `https://res.cloudinary.com/your_cloud_name/image/upload/c_pad,h_600,w_1000/b_rgb:000000/${publicId}.jpg`;
  //     // Replace "your_cloud_name" with your actual Cloudinary cloud name
  //     return {
  //       original: transformedImageUrl,
  //       thumbnail: transformedImageUrl, // You can adjust thumbnails if needed
  //     };
  //   });
  const images = [
    {
      original: "https://picsum.photos/id/1018/1000/600/",
      thumbnail: "https://picsum.photos/id/1018/250/150/",
    },
    {
      original: "https://picsum.photos/id/1015/1000/600/",
      thumbnail: "https://picsum.photos/id/1015/250/150/",
    },
    {
      original: "https://picsum.photos/id/1019/1000/600/",
      thumbnail: "https://picsum.photos/id/1019/250/150/",
    },
  ];
  //   const transformedImages = imageUrls.map((imageUrl) => {
  //     const transformedImageUrl = `${imageUrl.replace(
  //       "/upload/",
  //       "/upload/c_pad,h_600,w_1000,b_rgb:000000/"
  //     )}`;
  //     return {
  //       original: transformedImageUrl,
  //       thumbnail: transformedImageUrl, // You can adjust thumbnails if needed
  //     };
  //   });
  const transformedImages = imageUrls.map((imageUrl) => {
    return {
      original: imageUrl,
      thumbnail: imageUrl, // You can adjust thumbnails if needed
    };
  });
  return (
    <div style={{ width: 332, height: 187 }}>
      <ImageGallery
        items={transformedImages}
        showNav={false}
        showThumbnails={false}
        showPlayButton={false}
      />
    </div>
  );
};

export default ReactImageGallery;

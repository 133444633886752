import React from "react";
import { Link } from "react-router-dom";
import NavigationOffcanvas from "./NavigationOffcanvas";

const Navbar = () => {
  return (
    <>
      <NavigationOffcanvas></NavigationOffcanvas>
      <header>
        {/* <div class="collapse text-bg-white" id="navbarHeader">
        <div class="container">
          <div class="row">
            <div class="col-sm-8 col-md-7 py-4">
              <h4>About</h4>
              <p class="text-body-secondary">
                SoloStream is a revolutionary platform that empowers individuals
                to create and manage their own personalized websites for sharing
                content and engaging with their audience. SoloStream provides
                you with the tools and flexibility to showcase your unique
                brand, monetize your content, and build a loyal community, all
                within your own digital space.
              </p>
              <button type="button" class="btn btn-primary text-white">
                Primary
              </button>
            </div>
          </div>
        </div>
      </div> */}
        <div class="navbar bg-black">
          <div class="container col-md-9">
            <Link
              to="/"
              class="navbar-brand d-flex align-items-center text-white"
            >
              {/* <strong className="d-none d-md-block me-2 ">SoloStreamer</strong> */}
              <strong className=" me-2 ">SoloStreamer</strong>
              <span>
                <img
                  src="https://res.cloudinary.com/dx4nlfeak/image/upload/v1717438598/solostreamerProfilePictures/user/kq8ocwawxyousr6vjtv4.png"
                  className="img-fluid"
                  style={{ height: 27, width: 48, marginBottom: 2 }}
                ></img>
              </span>
              {/* <i class="bi bi-camera"></i> */}
            </Link>
            {/* <a
            class="navbar-toggler"
            data-bs-toggle="collapse"
            data-bs-target="#navbarHeader"
          >
            <span class="navbar-toggler-icon "></span>
          </a> */}
            <div>
              {/* <Link
                to="/dashboard"
                class="text-white text-decoration-none me-4"
              >
                DASHBOARD
              </Link>
              <Link to="/login" class="text-white text-decoration-none">
                LOG IN
              </Link>

              <a
                href="#"
                data-bs-toggle="offcanvas"
                data-bs-target="#navigationOffcanvas"
                className="text-white text-decoration-none"
              >
                <i class="bi bi-list"></i>
              </a> */}

              <button
                class="btn btn-sm btn-white"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#navigationOffcanvas"
              >
                <i class="bi bi-list"></i>
              </button>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Navbar;

var baseUrl = "http://localhost:5000";
var baseFrontendUrl = "http://localhost:3000";
var env_name = "test";
var stripe_public_key =
  "pk_test_51P4sXYJXJwSuFPKfi0FHsFc8AvpEjsZHUpporhYRlznueYhe06EIFMdSnkz94ypf6jeW2Zv9xznerKuaC06cscmd00zroII95i";

if (window.location.hostname === "test.solostreamer.com") {
  baseUrl = "https://test.api.solostreamer.com";
  baseFrontendUrl = "https://test.solostreamer.com";
} else if (window.location.hostname === "solostreamer.com") {
  baseUrl = "https://api.solostreamer.com";
  baseFrontendUrl = "https://solostreamer.com";
  stripe_public_key =
    "pk_live_51P4sXYJXJwSuFPKfhF0cWEQu9MLrGjXlOa0DsY1uzdP6AclzuPshrAteeIJj8o8REYfl4cptG9clA43yNfu1m0Pt00Ykx5y5im";
  env_name = "prod";
}

exports.baseUrl = baseUrl;
exports.baseFrontendUrl = baseFrontendUrl;
exports.env_name = env_name;
exports.stripe_public_key = stripe_public_key;

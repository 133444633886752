import React, { useEffect, useRef } from "react";

const CloudinaryUploadWidget = ({
  returnPhoto,
  folderName,
  className,
  content,
  croppingAspectRatio,
  mediaType,
}) => {
  const cloudinaryRef = useRef();
  const widgetRef = useRef();
  useEffect(() => {
    cloudinaryRef.current = window.cloudinary;
    widgetRef.current = cloudinaryRef.current.createUploadWidget(
      {
        cloudName: "dx4nlfeak",
        uploadPreset: "skoplt48",
        sources: ["local", "camera"],
        folder: `${folderName}`,
        maxImageFileSize: 6000000,
        cropping: true,
        multiple: false,
        // multiple: true,
        showSkipCropButton: false,
        croppingAspectRatio: croppingAspectRatio,
        // clientAllowedFormats: [
        //   "png",
        //   "jpg",
        //   "jpeg",
        //   "mp4",
        //   "mov",
        //   "avi",
        //   "webm",
        //   "flv",
        // ],
        resourceType: mediaType,
        styles: {
          palette: {
            action: "#1ab67d",
            link: "#1ab67d",
            tabIcon: "#1ab67d",
          },
        },
      },
      (error, result) => {
        if (!error && result && result.event === "success") {
          returnPhoto(result.info.secure_url);
        }
      }
    );
  });
  return (
    <button
      type="button"
      className={className}
      onClick={() => widgetRef.current.open()}
    >
      {content}
    </button>
  );
};

export default CloudinaryUploadWidget;

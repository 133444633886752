import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import CloudinaryUploadWidget from "./CloudinaryUploadWidget";
import { createPost } from "../actions/postActions.js";
import CloudinaryVideoPlayer from "./CloudinaryVideoPlayer.js";
import { Modal } from "bootstrap";
import { getCreatorPageInformation } from "../actions/userActions.js";

const CreatePostModal = ({ userName }) => {
  const modalRef = useRef();

  const [mediaType, setMediaType] = useState("");
  const [media, setMedia] = useState("");
  const [caption, setCaption] = useState("");
  const [choosen, setChoosen] = useState(false);
  const dispatch = useDispatch();

  const createdPost = useSelector((state) => state.post);
  const { loading, post, message } = createdPost;

  const handleCreatePost = async () => {
    const postData = {
      contentType: mediaType,
      content: media,
      caption: caption,
    };
    await dispatch(createPost(postData));
    closeModal();
    dispatch(getCreatorPageInformation(userName));
  };
  const closeModal = () => {
    let postModal = document.getElementById("createPostModalCloseButton");
    postModal.click();
  };
  useEffect(() => {
    var myModal = modalRef.current;
    var bsModal = new Modal(myModal);
  }, []);

  return (
    <div
      class="modal fade"
      id="createNewPostModal"
      tabIndex="-1"
      ref={modalRef}
    >
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="createNewPostModalLabel">
              Create new post
            </h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="createPostModalCloseButton"
              onClick={() => {
                setMedia("");
                setChoosen(false);
                setMediaType("");
              }}
            ></button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label htmlFor="inputMediaType" class="form-label">
                Post type
              </label>
              <select
                id="inputMediaType"
                className="form-select"
                onChange={(event) => {
                  setChoosen(true);
                  setMediaType(event.target.value);
                }}
                value={mediaType}
              >
                <option value="" disabled>
                  Choose...
                </option>
                <option value="Photo" disabled={choosen}>
                  Photo
                </option>
                <option value="Video" disabled={choosen}>
                  Video
                </option>
              </select>
            </div>
            {mediaType && (
              <>
                <label className="form-label">Media</label>
                <div class="mb-3 border rounded">
                  <div className="p-3 mt-2">
                    {media ? (
                      <div class="position-relative">
                        {mediaType === "Photo" ? (
                          <>
                            <img src={media} className="img-fluid" alt=""></img>
                            <div class="position-absolute top-0 end-0">
                              <span
                                class="badge text-bg-danger me-2 mt-2"
                                style={{ cursor: "pointer" }}
                                onClick={() => setMedia("")}
                              >
                                <i class="bi bi-trash"></i>
                              </span>
                            </div>
                          </>
                        ) : (
                          <div className="d-flex justify-content-center">
                            <CloudinaryVideoPlayer
                              url={media}
                            ></CloudinaryVideoPlayer>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div>
                        <div className="d-flex justify-content-center">
                          {mediaType === "Photo" ? (
                            <CloudinaryUploadWidget
                              returnPhoto={setMedia}
                              folderName={`user/media/`}
                              className={"btn btn-outline-dark"}
                              content={<i class="bi bi-plus-lg"></i>}
                              croppingAspectRatio={16 / 9}
                              mediaType={"image"}
                            ></CloudinaryUploadWidget>
                          ) : (
                            <CloudinaryUploadWidget
                              returnPhoto={setMedia}
                              folderName={`user/media/`}
                              className={"btn btn-outline-dark"}
                              content={<i class="bi bi-plus-lg"></i>}
                              // croppingAspectRatio={1.3}
                              mediaType={"video"}
                            ></CloudinaryUploadWidget>
                          )}
                        </div>
                        <p class="fw-normal text-primary text-center mt-2">
                          Upload your media here.
                        </p>
                        <div id="mediaHelp" className="form-text text-center">
                          Photos and videos are best with an aspect ratio of
                          16:9.
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="postCaption" className="form-label">
                    Post caption
                  </label>
                  <textarea
                    className="form-control"
                    id="postCaption"
                    rows="3"
                    defaultValue={caption}
                    onBlur={(event) => setCaption(event.target.value)}
                  ></textarea>
                </div>
              </>
            )}
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={() => {
                setMedia("");
                setChoosen(false);
                setMediaType("");
              }}
            >
              Close
            </button>
            {/* {loading ? } */}
            {loading ? (
              <button class="btn btn-primary text-white" disabled>
                <span class="spinner-border spinner-border-sm me-2"></span>
                <span role="status">Create post</span>
              </button>
            ) : (
              <button
                type="button"
                class="btn btn-primary text-white"
                onClick={() => handleCreatePost()}
              >
                Create post
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatePostModal;

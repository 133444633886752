import axios from "axios";
import {
  CANCEL_USER_SUBSCRIPTION_FAIL,
  CANCEL_USER_SUBSCRIPTION_REQUEST,
  CANCEL_USER_SUBSCRIPTION_SUCCESS,
  GET_SUBSCRIPTION_SCREEN_INFO_FAIL,
  GET_SUBSCRIPTION_SCREEN_INFO_REQUEST,
  GET_SUBSCRIPTION_SCREEN_INFO_SUCCESS,
  GET_USER_SUBSCRIPTION_SCREEN_INFO_FAIL,
  GET_USER_SUBSCRIPTION_SCREEN_INFO_REQUEST,
  GET_USER_SUBSCRIPTION_SCREEN_INFO_SUCCESS,
} from "../constants/subscriptionConstants";

export const getCreateSubscriptionScreenInformation =
  (creatorUsername, priceId) => async (dispatch) => {
    try {
      dispatch({
        type: GET_SUBSCRIPTION_SCREEN_INFO_REQUEST,
      });
      const { data } = await axios.get(
        `/api/subscriptions/${creatorUsername}/${priceId}`
      );
      dispatch({
        type: GET_SUBSCRIPTION_SCREEN_INFO_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;

      dispatch({
        type: GET_SUBSCRIPTION_SCREEN_INFO_FAIL,
        payload: message,
      });
    }
  };

export const getUserSubscriptionScreenInformation = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_USER_SUBSCRIPTION_SCREEN_INFO_REQUEST,
    });
    const { data } = await axios.get("/api/subscriptions/");
    dispatch({
      type: GET_USER_SUBSCRIPTION_SCREEN_INFO_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: GET_USER_SUBSCRIPTION_SCREEN_INFO_FAIL,
      payload: message,
    });
  }
};

export const cancelUserSubscription =
  (subscriptionId, reason) => async (dispatch) => {
    try {
      dispatch({
        type: CANCEL_USER_SUBSCRIPTION_REQUEST,
      });
      const { data } = await axios({
        method: "post",
        url: `/api/subscriptions/cancel-subscription/${subscriptionId}`,
        data: { cancellationReason: reason },
      });
      dispatch({
        type: CANCEL_USER_SUBSCRIPTION_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;

      dispatch({
        type: CANCEL_USER_SUBSCRIPTION_FAIL,
        payload: message,
      });
    }
  };

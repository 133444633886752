import React from "react";
import Footer from "../components/Footer";

const PrivacyPolicyScreen = () => {
  return (
    <div class="container mt-5">
      <h1 class="mb-4">Privacy Policy</h1>
      <p>Last updated: May 29, 2024</p>
      <p>
        Welcome to SoloStreamer! This Privacy Policy explains how we collect,
        use, disclose, and safeguard your information when you use our services.
        Please read this policy carefully to understand our views and practices
        regarding your personal data and how we will treat it.
      </p>

      <h2 class="mt-4">1. Information We Collect</h2>
      <p>We collect the following information:</p>
      <ul>
        <li>
          <strong>Google Login Information:</strong> When you log in using
          Google, we collect your name, email address, and profile picture.
        </li>
        <li>
          <strong>Payment Information:</strong> We use Stripe for payment
          processing. When you make a purchase, Stripe collects your payment
          information. We do not store any of your payment details on our
          servers.
        </li>
      </ul>

      <h2 class="mt-4">2. How We Use Your Information</h2>
      <p>We use the information we collect in the following ways:</p>
      <ul>
        <li>To provide and maintain our service.</li>
        <li>To manage your account and provide customer support.</li>
        <li>To process your transactions through Stripe.</li>
        <li>
          To communicate with you, including sending updates and promotional
          materials.
        </li>
      </ul>

      <h2 class="mt-4">3. Sharing Your Information</h2>
      <p>
        We do not share your personal information with third parties, except:
      </p>
      <ul>
        <li>When required by law or to respond to legal process.</li>
        <li>
          To protect the rights and safety of our users and third parties.
        </li>
        <li>With your consent or at your direction.</li>
      </ul>

      <h2 class="mt-4">4. Data Security</h2>
      <p>
        We implement robust security measures to protect your personal data.
        However, please note that no method of transmission over the internet or
        method of electronic storage is 100% secure, and we cannot guarantee
        absolute security. By using our service, you acknowledge and accept this
        risk.
      </p>

      <h2 class="mt-4">5. Limitation of Liability</h2>
      <p>
        SoloStreamer is not responsible for any content that is uploaded or
        shared on the platform. We do not guarantee the safety or security of
        your content. Users and creators are solely responsible for ensuring
        their content does not violate any laws or third-party rights. We are
        not liable for any unauthorized access, use, or disclosure of your
        content or personal information.
      </p>

      <h2 class="mt-4">6. Your Privacy Choices</h2>
      <p>
        You can manage your account settings to update your information or
        deactivate your account. If you wish to delete your account, please
        contact us, and we will process your request promptly.
      </p>

      <h2 class="mt-4">7. Children's Privacy</h2>
      <p>
        Our service is not intended for children under the age of 18. We do not
        knowingly collect personal information from children under 18. If we
        become aware that we have collected personal data from a child under 18
        without verification of parental consent, we will take steps to delete
        that information.
      </p>

      <h2 class="mt-4">8. Changes to This Privacy Policy</h2>
      <p>
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page. You are
        advised to review this Privacy Policy periodically for any changes.
      </p>

      <h2 class="mt-4">9. Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, please contact us
        at:
      </p>
      <p>Email: solostreamer.official@gmail.com</p>
      <Footer></Footer>
    </div>
  );
};

export default PrivacyPolicyScreen;

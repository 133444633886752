import React, { useEffect } from "react";
import ChatDetails from "../components/ChatDetails";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

const ChatDetailsScreen = () => {
  const { chatId } = useParams();
  const navigate = useNavigate();
  const userLogin = useSelector((state) => state.userLogin);
  const { isAuthenticated, loading: userLoading } = userLogin;

  useEffect(() => {
    if (isAuthenticated === false) {
      navigate("/login");
    }
    console.log(chatId);
  }, [isAuthenticated]);
  return (
    <div className="container justify-content-center col-lg-9 mt-4">
      {userLoading && <h1>Loading</h1>}
      {isAuthenticated === true && <ChatDetails chatId={chatId}></ChatDetails>}
    </div>
  );
};

export default ChatDetailsScreen;

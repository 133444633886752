import axios from "axios";
import {
  CREATE_POST_FAIL,
  CREATE_POST_REQUEST,
  CREATE_POST_SUCCESS,
  DELETE_POST_FAIL,
  DELETE_POST_REQUEST,
  DELETE_POST_SUCCESS,
  LIKE_POST_FAIL,
  LIKE_POST_REQUEST,
  LIKE_POST_SUCCESS,
  UPDATE_POST_FAIL,
  UPDATE_POST_REQUEST,
  UPDATE_POST_SUCCESS,
} from "../constants/postConstants";

export const createPost = (postInfo) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_POST_REQUEST,
    });
    const { data } = await axios({
      method: "post",
      url: "/api/posts/create/",
      data: postInfo,
    });
    dispatch({
      type: CREATE_POST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: CREATE_POST_FAIL,
      payload: message,
    });
  }
};

export const updatePost = (postInfo, postId) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_POST_REQUEST,
    });
    const { data } = await axios({
      method: "put",
      url: `/api/posts/create/${postId}`,
      data: postInfo,
    });
    dispatch({
      type: UPDATE_POST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: UPDATE_POST_FAIL,
      payload: message,
    });
  }
};

export const deletePost = (postId) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_POST_REQUEST,
    });
    const { data } = await axios.delete(`/api/posts/delete/${postId}`);
    dispatch({
      type: DELETE_POST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: DELETE_POST_FAIL,
      payload: message,
    });
  }
};

export const likePost = (postId) => async (dispatch) => {
  try {
    dispatch({
      type: LIKE_POST_REQUEST,
    });
    const { data } = await axios.get(`/api/posts/like/${postId}`);
    dispatch({
      type: LIKE_POST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: LIKE_POST_FAIL,
      payload: message,
    });
  }
};

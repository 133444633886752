export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";
export const GET_USER_INFO_REQUEST = "GET_USER_INFO_REQUEST";
export const GET_USER_INFO_SUCCESS = "GET_USER_INFO_SUCCESS";
export const GET_USER_INFO_FAIL = "GET_USER_INFO_FAIL";
export const EDIT_CREATOR_INFO_REQUEST = "EDIT_CREATOR_INFO_REQUEST";
export const EDIT_CREATOR_INFO_SUCCESS = "EDIT_CREATOR_INFO_SUCCESS";
export const EDIT_CREATOR_INFO_FAIL = "EDIT_CREATOR_INFO_FAIL";
export const PAYOUT_CREATOR_REQUEST = "PAYOUT_CREATOR_REQUEST";
export const PAYOUT_CREATOR_SUCCESS = "PAYOUT_CREATOR_SUCCESS";
export const PAYOUT_CREATOR_FAIL = "PAYOUT_CREATOR_FAIL";
export const GET_CREATOR_PAGE_INFORMATION_REQUEST =
  "GET_CREATOR_PAGE_INFORMATION_REQUEST";
export const GET_CREATOR_PAGE_INFORMATION_SUCCESS =
  "GET_CREATOR_PAGE_INFORMATION_SUCCESS";
export const GET_CREATOR_PAGE_INFORMATION_FAIL =
  "GET_CREATOR_PAGE_INFORMATION_FAIL";
export const GET_CREATOR_DASHBOARD_INFORMATION_REQUEST =
  "GET_CREATOR_DASHBOARD_INFORMATION_REQUEST";
export const GET_CREATOR_DASHBOARD_INFORMATION_SUCCESS =
  "GET_CREATOR_DASHBOARD_INFORMATION_SUCCESS";
export const GET_CREATOR_DASHBOARD_INFORMATION_FAIL =
  "GET_CREATOR_DASHBOARD_INFORMATION_FAIL";
export const SUBMIT_CREATOR_INFO_REQUEST = "SUBMIT_CREATOR_INFO_REQUEST";
export const SUBMIT_CREATOR_INFO_SUCCESS = "SUBMIT_CREATOR_INFO_SUCCESS";
export const SUBMIT_CREATOR_INFO_FAIL = "SUBMIT_CREATOR_INFO_FAIL";
export const GET_CREATOR_SUBSCRIPTION_PRICES_REQUEST =
  "GET_CREATOR_SUBSCRIPTION_PRICES_REQUEST";
export const GET_CREATOR_SUBSCRIPTION_PRICES_SUCCESS =
  "GET_CREATOR_SUBSCRIPTION_PRICES_SUCCESS";
export const GET_CREATOR_SUBSCRIPTION_PRICES_FAIL =
  "GET_CREATOR_SUBSCRIPTION_PRICES_FAIL";
export const GET_CREATOR_TIP_SCREEN_INFORMATION_REQUEST =
  "GET_CREATOR_TIP_SCREEN_INFORMATION_REQUEST";
export const GET_CREATOR_TIP_SCREEN_INFORMATION_SUCCESS =
  "GET_CREATOR_TIP_SCREEN_INFORMATION_SUCCESS";
export const GET_CREATOR_TIP_SCREEN_INFORMATION_FAIL =
  "GET_CREATOR_SUBSCRIPTION_PRICES_FAIL";

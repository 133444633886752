import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { stripe_public_key } from "../config/env";

import SendTipCheckoutForm from "../components/SendTipCheckoutForm";
import { getCreateSubscriptionScreenInformation } from "../actions/subscriptionActions";
import { getTipCreationScreenInformation } from "../actions/userActions";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(stripe_public_key);
const SendTipScreen = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const searchParams = new URLSearchParams(location.search);
  //   const creatorUsername = searchParams.get("creatorUsername");
  const creatorUsername = params.creatorUsername;
  const priceId = params.priceId;

  const userLogin = useSelector((state) => state.userLogin);
  const { isAuthenticated, loading: userLoading } = userLogin;

  const tipCreationScreen = useSelector((state) => state.tipCreationScreen);
  const { loading: subscriptionLoading, creatorInformation } =
    tipCreationScreen;

  useEffect(() => {
    if (isAuthenticated === true) {
      dispatch(getTipCreationScreenInformation(creatorUsername));
    }
    if (isAuthenticated === false) {
      navigate(`/login?redirect=${location.pathname}`);
    }
  }, [isAuthenticated]);
  return (
    <>
      {(userLoading || subscriptionLoading) && (
        <>
          <div className="row mx-3 justify-content-center">
            <div className="col-lg-6 mt-0 p-2">
              <div class="card mx-1">
                <div class="card-body">
                  <h5 class="card-title placeholder-glow">
                    <span class="placeholder col-6"></span>
                  </h5>
                  <p class="card-text placeholder-glow">
                    <span class="placeholder col-7"></span>
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-6"></span>
                    <span class="placeholder col-8"></span>
                  </p>
                  <a
                    class="btn btn-primary disabled placeholder col-6"
                    aria-disabled="true"
                  ></a>
                </div>
              </div>
            </div>
          </div>
          <div className="row mx-3 justify-content-center">
            <div className="col-lg-6">
              <div class="my-3">
                <div class="vstack gap-3 px-0">
                  <div className="border rounded p-2">
                    <p class="placeholder-glow mb-1">
                      <span class="placeholder col-4"></span>
                      <span class="placeholder col-9"></span>
                      <span class="placeholder col-12"></span>
                    </p>
                  </div>
                  <div className="border rounded p-2">
                    <p class="placeholder-glow mb-1">
                      <span class="placeholder col-4"></span>
                      <span class="placeholder col-9"></span>
                      <span class="placeholder col-12"></span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {!subscriptionLoading && isAuthenticated && creatorInformation && (
        <div className="container justify-content-center col-md-5 my-4">
          <h6 className="text-muted mt-4">CREATOR INFORMATION</h6>
          <div className="border rounded">
            <div className="p-3">
              <div className="d-flex align-items-center">
                <div className="me-2">
                  <img
                    src={creatorInformation.profilePhoto}
                    class="bd-placeholder-img rounded-circle border-white"
                    alt=""
                    style={{ width: 50, height: 50 }}
                  ></img>
                </div>
                <div className="flex-column ms-2">
                  <p className="mb-0">
                    <small>
                      Creator since {creatorInformation.formattedDate}
                    </small>
                  </p>
                  <h5 className=" mb-0">@{creatorInformation.userName}</h5>
                  <Link
                    className=" mb-0 text-decoration-none"
                    to={`/${creatorInformation.userName}`}
                  >
                    <small className="text-primary">View page</small>
                  </Link>
                </div>
              </div>
              <hr></hr>
              <p class="mb-1">
                <i class="bi bi-check-lg text-primary"></i> Tips gain access to
                exclusive content or perks
              </p>
              <p class="mb-1">
                <i class="bi bi-check-lg text-primary"></i> Tips foster a closer
                connection with the creator.
              </p>
              <p class="mb-1">
                <i class="bi bi-check-lg text-primary"></i> Tips provide direct
                financial support to the creator.
              </p>
            </div>
          </div>

          <Elements
            stripe={stripePromise}
            const
            options={{
              mode: "payment",
              amount: 1099,
              currency: "usd",

              // Fully customizable with appearance API.
              appearance: {
                /*...*/
              },
            }}
          >
            <SendTipCheckoutForm creatorUsername={creatorUsername} />
          </Elements>
        </div>
      )}
    </>
  );
};

export default SendTipScreen;

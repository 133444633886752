import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import CloudinaryUploadWidget from "./CloudinaryUploadWidget.js";
import { createPost, deletePost } from "../actions/postActions.js";
import CloudinaryVideoPlayer from "./CloudinaryVideoPlayer.js";
import { Modal } from "bootstrap";
import {
  getCreatorDashboardInformation,
  getCreatorPageInformation,
  payoutCreator,
} from "../actions/userActions.js";

const PayoutCreatorModal = () => {
  const modalRef = useRef();

  const dispatch = useDispatch();
  const creatorDashboardInformation = useSelector(
    (state) => state.creatorDashboardInformation
  );
  const {
    balancePending,
    balanceAvailable,
    creatorInformation: { userName },
  } = creatorDashboardInformation;
  const creatorPayout = useSelector((state) => state.creatorPayout);
  const { loading } = creatorPayout;

  const closeModal = () => {
    let postModal = document.getElementById("payoutCreatorModalCloseButton");
    postModal.click();
  };
  const handleCreatorPayout = async () => {
    await dispatch(payoutCreator());
    dispatch(getCreatorDashboardInformation(userName));

    closeModal();
  };
  useEffect(() => {
    var myModal = modalRef.current;
    var bsModal = new Modal(myModal);
  }, []);

  return (
    <div
      class="modal fade"
      id="payoutCreatorModal"
      tabIndex="-1"
      ref={modalRef}
    >
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 className="text-muted mb-0">PAYOUT INFORMATION</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              id="payoutCreatorModalCloseButton"
            ></button>
          </div>
          <div class="modal-body p-4">
            {/* <h5 class="mb-0">Delete this post?</h5>
            <p class="mb-0">
              Once a post is listed you cannot update it, you can create another
              post or delete this one.
            </p> */}

            <p class="mb-1">
              <strong>
                You have ${(balanceAvailable / 100).toFixed(2)} available to
                send to your bank right now.
              </strong>
            </p>
            <p class="mb-1">
              <small>
                There is a{" "}
                <strong>
                  $
                  {((Math.ceil(balanceAvailable * 0.01) + 200) / 100).toFixed(
                    2
                  )}
                </strong>{" "}
                fee to send this to your bank ($2 + 1%)
              </small>
            </p>

            <h6 className="text-muted mt-3 mb-0">EXAMPLE PAYOUTS</h6>

            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Payout</th>
                  <th scope="col">Fee</th>
                  <th scope="col">Net</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>${(balanceAvailable / 100).toFixed(2)}</td>
                  <td>
                    $
                    {((Math.ceil(balanceAvailable * 0.01) + 200) / 100).toFixed(
                      2
                    )}
                  </td>
                  <td>
                    $
                    {(
                      (balanceAvailable -
                        (Math.ceil(balanceAvailable * 0.01) + 200)) /
                      100
                    ).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td>${(10000 / 100).toFixed(2)}</td>
                  <td>${((10000 * 0.01 + 200) / 100).toFixed(2)}</td>
                  <td>${((10000 - (10000 * 0.01 + 200)) / 100).toFixed(2)}</td>
                </tr>
                <tr>
                  <td>${(20000 / 100).toFixed(2)}</td>
                  <td>${((20000 * 0.01 + 200) / 100).toFixed(2)}</td>
                  <td>${((20000 - (20000 * 0.01 + 200)) / 100).toFixed(2)}</td>
                </tr>
              </tbody>
            </table>
            <p class="mb-1">
              <small>The larger your payout the lower the fee is.</small>
            </p>
          </div>
          <div class="modal-footer flex-nowrap p-0">
            <button
              onClick={() => handleCreatorPayout()}
              disabled={loading}
              type="button"
              class="btn btn-lg btn-primary fs-6  text-decoration-none col-6 py-3 m-0 rounded-0 border-end"
            >
              <strong>Send funds to bank</strong>
            </button>
            <button
              type="button"
              disabled={loading}
              class="btn btn-lg btn-primary fs-6  text-decoration-none col-6 py-3 m-0 rounded-0"
              data-bs-dismiss="modal"
            >
              Nevermind
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayoutCreatorModal;

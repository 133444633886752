import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import CloudinaryUploadWidget from "./CloudinaryUploadWidget";
import { createPost, deletePost } from "../actions/postActions.js";
import CloudinaryVideoPlayer from "./CloudinaryVideoPlayer.js";
import { Modal } from "bootstrap";
import { getCreatorPageInformation } from "../actions/userActions.js";

const DeletePostModal = ({ post, userName }) => {
  const modalRef = useRef();

  const dispatch = useDispatch();

  const handleDeletePost = async () => {
    await dispatch(deletePost(post._id));
    closeModal();
    dispatch(getCreatorPageInformation(userName));
  };
  const closeModal = () => {
    let postModal = document.getElementById("deletePostModalCloseButton");
    postModal.click();
  };
  useEffect(() => {
    var myModal = modalRef.current;
    var bsModal = new Modal(myModal);
  }, []);
  return (
    <div class="modal fade" id="deletePostModal" tabIndex="-1" ref={modalRef}>
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="deletePostModalLabel">
              Edit Post
            </h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              id="deletePostModalCloseButton"
            ></button>
          </div>
          <div class="modal-body p-4">
            <h5 class="">Delete this post?</h5>
            <p class="mb-0">
              Once a post is listed you cannot update it, you can create another
              post or delete this one.
            </p>
          </div>
          <div class="modal-footer flex-nowrap p-0">
            <button
              onClick={() => handleDeletePost()}
              type="button"
              class="btn btn-lg btn-primary fs-6 text-decoration-none col-6 py-3 m-0 rounded-0 border-end"
            >
              <strong>Delete post</strong>
            </button>
            <button
              type="button"
              class="btn btn-lg btn-primary fs-6 text-decoration-none col-6 py-3 m-0 rounded-0"
              data-bs-dismiss="modal"
            >
              No thanks
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeletePostModal;

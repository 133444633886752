import {
  EDIT_CREATOR_INFO_FAIL,
  EDIT_CREATOR_INFO_REQUEST,
  EDIT_CREATOR_INFO_SUCCESS,
  GET_CREATOR_DASHBOARD_INFORMATION_FAIL,
  GET_CREATOR_DASHBOARD_INFORMATION_REQUEST,
  GET_CREATOR_DASHBOARD_INFORMATION_SUCCESS,
  GET_CREATOR_INFO_FAIL,
  GET_CREATOR_INFO_REQUEST,
  GET_CREATOR_INFO_SUCCESS,
  GET_CREATOR_PAGE_INFORMATION_FAIL,
  GET_CREATOR_PAGE_INFORMATION_REQUEST,
  GET_CREATOR_PAGE_INFORMATION_SUCCESS,
  GET_CREATOR_SUBSCRIPTION_PRICES_FAIL,
  GET_CREATOR_SUBSCRIPTION_PRICES_REQUEST,
  GET_CREATOR_SUBSCRIPTION_PRICES_SUCCESS,
  GET_CREATOR_TIP_SCREEN_INFORMATION_FAIL,
  GET_CREATOR_TIP_SCREEN_INFORMATION_REQUEST,
  GET_CREATOR_TIP_SCREEN_INFORMATION_SUCCESS,
  GET_USER_INFO_FAIL,
  GET_USER_INFO_REQUEST,
  GET_USER_INFO_SUCCESS,
  PAYOUT_CREATOR_FAIL,
  PAYOUT_CREATOR_REQUEST,
  PAYOUT_CREATOR_SUCCESS,
  SUBMIT_CREATOR_INFO_FAIL,
  SUBMIT_CREATOR_INFO_REQUEST,
  SUBMIT_CREATOR_INFO_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
} from "../constants/userConstants";

export const userLoginReducer = (state = { loading: false }, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return {
        loading: true,
      };
    case SUBMIT_CREATOR_INFO_REQUEST:
    case GET_USER_INFO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case USER_LOGIN_SUCCESS:
    case GET_USER_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        isCreator: action.payload.isCreator,
        user: action.payload,
      };

    case USER_LOGIN_FAIL:
    case GET_USER_INFO_FAIL:
      return {
        loading: false,
        isCreator: false,
        isAuthenticated: false,
        user: null,
        error: action.payload,
      };
    case SUBMIT_CREATOR_INFO_FAIL:
      return {
        ...state,
        loading: false,
        isCreator: false,
        error: action.payload,
      };
    case USER_LOGOUT:
      return {
        loading: false,
        isCreator: false,
        isAuthenticated: false,
        user: null,
      };
    default:
      return state;
  }
};

export const creatorSubscriptionPricesReducer = (
  state = { loading: false, subscriptionPrices: [] },
  action
) => {
  switch (action.type) {
    case GET_CREATOR_SUBSCRIPTION_PRICES_REQUEST:
      return {
        loading: true,
        subscriptionPrices: [],
      };
    case GET_CREATOR_SUBSCRIPTION_PRICES_SUCCESS:
      return {
        loading: false,
        subscriptionPrices: action.payload,
      };

    case GET_CREATOR_SUBSCRIPTION_PRICES_FAIL:
      return {
        loading: false,
        subscriptionPrices: [],
        error: action.payload,
      };

    default:
      return state;
  }
};

export const editCreatorInformationReducer = (
  state = { loading: false, message: null, user: null },
  action
) => {
  switch (action.type) {
    case EDIT_CREATOR_INFO_REQUEST:
      return {
        loading: true,
        message: null,
      };
    case EDIT_CREATOR_INFO_SUCCESS:
      return {
        loading: false,
        message: action.payload,
      };

    case EDIT_CREATOR_INFO_FAIL:
      return {
        loading: false,
        message: null,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const creatorPageInformationReducer = (
  state = {
    loading: false,
    creatorInformation: null,
    creatorPosts: [],
    photoPosts: [],
    subscriptionPlans: [],
    totalLikes: 0,
    numberOfPhotos: 0,
    numberOfVideos: 0,
    isSubscribed: false,
  },
  action
) => {
  switch (action.type) {
    case GET_CREATOR_PAGE_INFORMATION_REQUEST:
      return {
        loading: true,
        creatorInformation: null,
        creatorPosts: [],
        photoPosts: [],

        subscriptionPlans: [],
        totalLikes: 0,
        numberOfPhotos: 0,
        numberOfVideos: 0,
        isSubscribed: false,
      };
    case GET_CREATOR_PAGE_INFORMATION_SUCCESS:
      return {
        loading: false,
        creatorInformation: action.payload.creatorInformation,
        creatorPosts: action.payload.creatorPosts,
        photoPosts: action.payload.photoPosts,

        subscriptionPlans: action.payload.subscriptionPlans,
        totalLikes: action.payload.totalLikes,
        numberOfPhotos: action.payload.numberOfPhotos,
        numberOfVideos: action.payload.numberOfVideos,
        isSubscribed: action.payload.isSubscribed,
      };

    case GET_CREATOR_PAGE_INFORMATION_FAIL:
      return {
        loading: false,
        creatorInformation: null,
        creatorPosts: [],
        photoPosts: [],
        subscriptionPlans: [],
        totalLikes: 0,
        numberOfPhotos: 0,
        numberOfVideos: 0,
        isSubscribed: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const creatorDashboardInformationReducer = (
  state = {
    loading: false,
    creatorInformation: null,
    activeSubscriptions: [],
    cancelledSubscriptions: [],
    recievedTips: [],
    balancePending: null,
    balanceAvailable: null,
  },
  action
) => {
  switch (action.type) {
    case GET_CREATOR_DASHBOARD_INFORMATION_REQUEST:
      return {
        loading: true,
        creatorInformation: null,
        activeSubscriptions: [],
        cancelledSubscriptions: [],
        receivedTips: [],

        balancePending: null,
        balanceAvailable: null,
      };
    case GET_CREATOR_DASHBOARD_INFORMATION_SUCCESS:
      return {
        loading: false,
        creatorInformation: action.payload.creatorInformation,
        activeSubscriptions: action.payload.activeSubscriptions,
        cancelledSubscriptions: action.payload.cancelledSubscriptions,
        receivedTips: action.payload.receivedTips,
        balancePending: action.payload.balancePending,
        balanceAvailable: action.payload.balanceAvailable,
      };

    case GET_CREATOR_DASHBOARD_INFORMATION_FAIL:
      return {
        loading: false,
        creatorInformation: null,
        activeSubscriptions: [],
        cancelledSubscriptions: [],
        receivedTips: [],

        balancePending: null,
        balanceAvailable: null,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const creatorPayoutReducer = (
  state = {
    loading: false,
    message: null,
  },
  action
) => {
  switch (action.type) {
    case PAYOUT_CREATOR_REQUEST:
      return {
        loading: true,
        message: null,
      };
    case PAYOUT_CREATOR_SUCCESS:
      return {
        loading: false,
        message: action.payload.message,
      };

    case PAYOUT_CREATOR_FAIL:
      return {
        loading: false,
        message: null,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const tipCreationScreenReducer = (
  state = {
    loading: false,
    creatorInformation: null,
    customer: null,
  },
  action
) => {
  switch (action.type) {
    case GET_CREATOR_TIP_SCREEN_INFORMATION_REQUEST:
      return {
        loading: true,
        creatorInformation: null,
        customer: null,
      };
    case GET_CREATOR_TIP_SCREEN_INFORMATION_SUCCESS:
      return {
        loading: false,
        creatorInformation: action.payload.creatorInformation,
        customer: action.payload.customer,
      };
    case GET_CREATOR_TIP_SCREEN_INFORMATION_FAIL:
      return {
        loading: false,
        creatorInformation: null,
        customer: null,
        error: action.payload,
      };

    default:
      return state;
  }
};

import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Link,
  useLocation,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getUserInformation,
  submitCreatorInformation,
} from "../actions/userActions";

const SignUpScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [userName, setUsername] = useState("");
  const [userNameValid, setUsernameValid] = useState(true);
  const [ageAgreed, setAgeAgreed] = useState(false);
  const [ageAgreedValid, setAgeAgreedValid] = useState(true);
  const [firstName, setFirstname] = useState("");
  const [lastName, setLastname] = useState("");
  const [reference, setReference] = useState("");
  const [redirectLoading, setRedirectLoading] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { isAuthenticated, user, isCreator, loading } = userLogin;

  useEffect(() => {
    if (isAuthenticated === false) {
      navigate(`/login?redirect=${location.pathname}`);
    }
    if (isAuthenticated === true) {
      dispatch(getUserInformation());
      setFirstname(user.firstName);
      setLastname(user.lastName);
      // These are to prevent the error. If they already have input the inital info. It wont change the info
      setUsername(user.userName);
      setReference(user.reference);
      // Check the
      // if(user.stripeAccountId){

      // }
    }
    if (isCreator === true) {
      navigate(`/dashboard/${user.userName}`);
    }
  }, [isAuthenticated, isCreator]);

  const redirectToStripeOnboarding = async () => {
    try {
      setRedirectLoading(true);
      const { data } = await axios.get("/api/users/get-onboarding-link/");
      // To open in new window use for express dash
      // window.open(data, "_blank");
      window.location.href = data;
      setRedirectLoading(false);
    } catch (error) {
      setRedirectLoading(false);
      console.log(error);
    }
  };
  const handleSubmit = async () => {
    var permissionIssue;
    var userNameIssue;
    if (!ageAgreed) {
      setAgeAgreedValid(false);
      permissionIssue = true;
    }
    if (!userName) {
      setUsernameValid(false);
      userNameIssue = true;
    }
    if (permissionIssue || userNameIssue) {
      return;
    }
    const data = {
      firstName: firstName,
      lastName: lastName,
      userName: userName,
      reference: reference,
    };
    await dispatch(submitCreatorInformation(data));
    dispatch(getUserInformation());
  };

  //   useEffect(() => {}, [user, accountLink, payments]);
  return (
    <>
      {isAuthenticated && user && (
        <>
          {!user.stripeAccountId ? (
            <div className="container justify-content-center col-md-5 my-4">
              <h2 className="text-center mb-4">Complete your profile</h2>

              <h6 className="text-muted mb-3">PERSONAL INFORMATION</h6>
              <div class="mb-3">
                <label htmlFor="fistNameInput" class="form-label">
                  First name
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="fistNameInput"
                  defaultValue={firstName}
                  onBlur={(event) => setFirstname(event.target.value)}
                ></input>
              </div>
              <div class="mb-3">
                <label htmlFor="lastNameInput" class="form-label">
                  Last name
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="lastNameInput"
                  defaultValue={lastName}
                  onBlur={(event) => setLastname(event.target.value)}
                ></input>
              </div>
              <div class="mb-3">
                <label htmlFor="inputCountry" class="form-label">
                  Country
                </label>
                <select id="inputCountry" class="form-select">
                  <option defaultValue={true}>United States</option>
                </select>
              </div>
              <div class="mb-3">
                <label htmlFor="userNameInput" class="form-label">
                  Username
                </label>
                <input
                  type="text"
                  className={`form-control ${
                    userNameValid ? "" : "is-invalid"
                  }`}
                  id="userNameInput"
                  defaultValue={userName}
                  onBlur={(event) => setUsername(event.target.value)}
                  onChange={() => setUsernameValid(true)}
                ></input>
                <div id="userNameHelp" class="form-text">
                  This is how people will find your page.
                </div>
                <div className="invalid-feedback">
                  You must enter a username
                </div>
              </div>
              <div class="mb-4">
                <label htmlFor="referenceInput" class="form-label">
                  Reference
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="referenceInput"
                  defaultValue={reference}
                  onBlur={(event) => setReference(event.target.value)}
                ></input>
                <div id="referenceHelp" class="form-text">
                  If someone told you to become a creator on our site.
                </div>
              </div>

              <h6 className="text-muted mb-3">AGE RESTRICTION</h6>
              <div class="mb-4">
                <label htmlFor="permissionCheckbox" class="form-label">
                  You <strong>MUST</strong> be 18 or older to become a creator.
                </label>
                <div class="form-check">
                  <input
                    className={`form-check-input ${
                      ageAgreedValid ? "" : "is-invalid"
                    }`}
                    type="checkbox"
                    checked={ageAgreed}
                    id="permissionCheckbox"
                    onChange={() => {
                      setAgeAgreed(!ageAgreed);
                      setAgeAgreedValid(true);
                    }}
                  ></input>
                  <label class="form-check-label" htmlFor="permissionCheckbox">
                    I am 18 or older. I have also read the terms and privacy.
                  </label>
                  <div className="invalid-feedback">
                    You must agree to this.
                  </div>
                </div>
              </div>
              <h6 className="text-muted mb-3">PAYMENT INFORMATION</h6>
              <div className="border rounded mb-4">
                <div className="d-flex flex-row p-3">
                  <p className="text-muted">
                    <small>
                      We use Stripe to make sure you get paid on time and to
                      keep your personal bank and details secure. Click Save and
                      continue to set up your payments on Stripe.
                    </small>
                  </p>
                  <h1 className="ms-3 pe-4 d-none d-md-block">
                    <i class="bi bi-stripe"></i>
                  </h1>
                </div>
              </div>

              <div class="d-grid gap-2">
                {loading ? (
                  <button
                    class="btn btn-primary text-white"
                    type="button"
                    disabled
                  >
                    <span class="spinner-border spinner-border-sm me-2"></span>
                    <span role="status">SAVE AND CONTINUE</span>
                  </button>
                ) : (
                  <button
                    class="btn btn-primary text-white"
                    type="button"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    SAVE AND CONTINUE
                  </button>
                )}
              </div>
            </div>
          ) : (
            <div className="container justify-content-center col-md-5 mt-4">
              <h2 className="text-center mb-4">
                Connect your account to Stripe
              </h2>
              <p className=" text-center text-muted">
                We use Stripe to make sure you get paid on time and keep your
                personal and bank details secure.
              </p>
              <div class="d-grid gap-2">
                {redirectLoading ? (
                  <button
                    class="btn btn-primary text-white"
                    type="button"
                    disabled
                  >
                    <span class="spinner-border spinner-border-sm me-2"></span>
                    <span role="status">SET UP PAYMENTS</span>
                  </button>
                ) : (
                  <button
                    class="btn btn-primary text-white"
                    type="button"
                    onClick={() => {
                      redirectToStripeOnboarding();
                    }}
                  >
                    SET UP PAYMENTS
                  </button>
                )}
              </div>
            </div>
          )}
        </>
      )}
      {/* {user === true && accountLink == false && (
        <div className="container justify-content-center col-md-5 mt-4">
          <h2 className="text-center mb-4">Complete your profile</h2>
          <form>
            <h6 className="text-muted mb-3">PERSONAL INFORMATION</h6>
            <div class="mb-3">
              <label for="fistNameInput" class="form-label">
                First name
              </label>
              <input
                type="text"
                class="form-control"
                id="fistNameInput"
              ></input>
            </div>
            <div class="mb-3">
              <label for="lastNameInput" class="form-label">
                Last name
              </label>
              <input
                type="text"
                class="form-control"
                id="lastNameInput"
              ></input>
            </div>
            <div class="mb-3">
              <label htmlFor="inputCountry" class="form-label">
                Country
              </label>
              <select id="inputCountry" class="form-select">
                <option selected>United States</option>
              </select>
            </div>
            <div class="mb-3">
              <label for="userNameInput" class="form-label">
                Username
              </label>
              <input
                type="text"
                class="form-control"
                id="userNameInput"
              ></input>
              <div id="userNameHelp" class="form-text">
                This is how people will find your page.
              </div>
            </div>
            <div class="mb-4">
              <label for="referenceInput" class="form-label">
                Reference
              </label>
              <input
                type="text"
                class="form-control"
                id="referenceInput"
              ></input>
              <div id="referenceHelp" class="form-text">
                If someone told you to become a creator on our site.
              </div>
            </div>
            <h6 className="text-muted mb-3">PAYMENT INFORMATION</h6>
            <div className="border rounded mb-4">
              <div className="d-flex flex-row p-3">
                <p className="text-muted">
                  <small>
                    We use Stripe to make sure you get paid on time and to keep
                    your personal bank and details secure. Click Save and
                    continue to set up your payments on Stripe.
                  </small>
                </p>
                <h1 className="ms-3 pe-4 d-none d-md-block">
                  <i class="bi bi-stripe"></i>
                </h1>
              </div>
            </div>
            <div class="d-grid gap-2">
              <button
                class="btn btn-primary text-white"
                type="button"
                onClick={() => {
                  setAccountLink(true);
                  setPayments(true);
                }}
              >
                SAVE AND CONTINUE (complete stripe onboarding)
              </button>
              <button
                class="btn btn-primary text-white"
                type="button"
                onClick={() => {
                  setAccountLink(true);
                  setPayments(false);
                }}
              >
                SAVE AND CONTINUE (incomplete stripe onboarding)
              </button>
            </div>
          </form>
        </div>
      )}
      {user === true && accountLink == true && payments === false && (
        <div className="container justify-content-center col-md-5 mt-4">
          <h2 className="text-center mb-4">Connect your account to Stripe</h2>
          <p className=" text-center text-muted">
            We use Stripe to make sure you get paid on time and keep your
            personal and bank details secure.
          </p>
          <div class="d-grid gap-2">
            <button
              class="btn btn-primary text-white"
              type="button"
              onClick={() => setPayments(true)}
            >
              SET UP PAYMENTS
            </button>
          </div>
        </div>
      )} */}
    </>
  );
};

export default SignUpScreen;

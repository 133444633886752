import React from "react";
import { Link } from "react-router-dom";

const LoginErrorScreen = () => {
  return (
    <div>
      <section class="py-5 text-center container">
        <div class="row py-lg-5">
          <div class="col-lg-6 col-md-8 mx-auto">
            <h1 class="fw-light">There was an issue with your login</h1>
            {/* <p class="lead text-muted">{searchParams.get("error")}</p> */}
            <p class="lead text-muted">
              We reccomend waiting a few minutes and trying again. If the issue
              persists please contact us!.
            </p>
            <p>
              <Link to="/" class="btn btn-primary text-white my-2">
                Go to home
              </Link>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LoginErrorScreen;

import React, { useState } from "react";
import axios from "axios";
import {
  useStripe,
  useElements,
  PaymentElement,
  AddressElement,
} from "@stripe/react-stripe-js";
import { baseFrontendUrl } from "../config/env";

const CheckoutForm = ({ creatorUsername, priceId }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleError = (error) => {
    setLoading(false);
    setErrorMessage(error.message);
  };

  const handleSubmitV1 = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: "https://example.com/order/123/complete",
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      handleError(submitError);
      return;
    }

    var addressElement = elements.getElement("address");

    const { complete, value } = await addressElement.getValue();
    console.log(value);
    // return;

    // Create subscription
    const { data } = await axios({
      method: "post",
      url: `/api/subscriptions/create-subscription/${creatorUsername}/${priceId}`,
      data: value,
    });
    // const { data } = await axios.get(
    //   `/api/subscriptions/create-subscription/${creatorUsername}/${priceId}`
    // );
    // Create the subscription
    // const res = await fetch("/create-subscription", {
    //   method: "POST",
    // });
    // const { type, clientSecret } = await res.json();
    const { clientSecret, subscription } = data;
    // const confirmIntent =
    //   type === "setup" ? stripe.confirmSetup : stripe.confirmPayment;
    const confirmIntent = stripe.confirmPayment;

    // Confirm the Intent using the details collected by the Payment Element
    const { error } = await confirmIntent({
      elements,
      clientSecret,
      confirmParams: {
        return_url: `${baseFrontendUrl}/${creatorUsername}`,
      },
    });

    if (error) {
      // This point is only reached if there's an immediate error when confirming the Intent.
      // Show the error to your customer (for example, "payment details incomplete").
      // await axios({
      //   method: "post",
      //   url: `/api/subscriptions/cancel-subscription/${subscription._id}`,
      //   data: { cancelationReason: "Inital payment failed." },
      // });
      // await axios.get(
      //   `/api/subscriptions/cancel-subscription/${subscription._id}`
      // );
      handleError(error);
    } else {
      // Your customer is redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer is redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <AddressElement
        options={{ mode: "shipping", allowedCountries: ["US"] }}
      />
      <PaymentElement />

      {loading ? (
        <button class="btn btn-primary text-white mt-3" type="submit" disabled>
          <span class="spinner-border spinner-border-sm me-2"></span>
          <span role="status">Subscribe now</span>
        </button>
      ) : (
        <button
          disabled={!stripe}
          class="btn btn-primary text-white mt-3"
          type="submit"
        >
          Subscribe now
        </button>
      )}

      {/* Show error message to your customers */}
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
};

export default CheckoutForm;

import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
// TODO - FIO PRODUCTION

import { firestore, firebase } from "../firebase";
import { useCollectionData } from "react-firebase-hooks/firestore";
import ReactImageGallery from "./ReactImageGallery";
import CloudinaryUploadWidget from "./CloudinaryUploadWidget";
import CloudinaryVideoPlayer from "./CloudinaryVideoPlayer";
import { Link } from "react-router-dom";

const ChatDetails = ({ chatId }) => {
  const dummy = useRef();
  const [mediaType, setMediaType] = useState("");
  const [isMessageValid, setIsMessageValid] = useState(true);
  const [mediaMessageLoading, setMediaMessageLoading] = useState(false);
  const [media, setMedia] = useState("");
  const [messageText, setMessageText] = useState("");
  const [choosen, setChoosen] = useState(false);
  const [participantProfile, setParticipantProfile] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  const userLogin = useSelector((state) => state.userLogin);
  const { user } = userLogin;

  const messagesRef = firestore
    .collection("chats")
    .doc(chatId)
    .collection("messages")
    .orderBy("createdAt");

  const [messages] = useCollectionData(messagesRef, { idField: "id" });

  const [input, setInput] = useState("");

  const handleSendMessage = async () => {
    if (input.trim()) {
      await firestore
        .collection("chats")
        .doc(chatId)
        .collection("messages")
        .add({
          text: input,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          senderId: user._id,
          photoURL: user.profilePhoto,
        });
      setInput("");
      // dummy.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const sendMediaToChat = async () => {
    if (!messageText) {
      setIsMessageValid(false);
      return;
    }
    if (messageText.trim()) {
      setMediaMessageLoading(true);
      await firestore
        .collection("chats")
        .doc(chatId)
        .collection("messages")
        .add({
          text: messageText,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          senderId: user._id,
          photoURL: user.profilePhoto,
          mediaType: mediaType,
          media: media,
        });
      let sendMediaModal = document.getElementById(
        "uploadMediaToChatModalCloseButton"
      );
      sendMediaModal.click();
      setMediaMessageLoading(false);

      // dummy.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const fetchParticipants = async () => {
    try {
      const chatDoc = await firestore.collection("chats").doc(chatId).get();
      if (chatDoc.exists) {
        const chatData = chatDoc.data();
        const participantIds = chatData.participants || [];
        const participantPromises = participantIds.map((id) =>
          firestore.collection("users").doc(id).get()
        );
        const participantDocs = await Promise.all(participantPromises);
        const participantProfiles = participantDocs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const loggedInUserProfile = participantProfiles.find(
          (profile) => profile.id === user._id
        );
        const otherParticipantProfile = participantProfiles.find(
          (profile) => profile.id !== user._id
        );

        setUserProfile(loggedInUserProfile);
        setParticipantProfile(otherParticipantProfile);
      }
    } catch (error) {
      console.error("Error fetching participants:", error);
    }
  };

  useEffect(() => {
    dummy.current.scrollIntoView({ behavior: "smooth" });

    fetchParticipants();
    const updateLastRead = async () => {
      await firestore
        .collection("chats")
        .doc(chatId)
        .update({
          [`lastRead_${user._id}`]:
            firebase.firestore.FieldValue.serverTimestamp(),
        });
    };

    // fetchParticipants();
    updateLastRead();
  }, [chatId, user._id, messages]);

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSendMessage();
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <Link to="/chats" className="text-decoration-none link-dark fs-4">
            <i class="bi bi-arrow-left"></i>
          </Link>
        </div>
        <div>
          {participantProfile && (
            <div class="d-flex align-items-center">
              <div class="me-2">
                <img
                  src={participantProfile.profilePicture}
                  class="bd-placeholder-img rounded-circle border-white"
                  alt=""
                  style={{ width: 40, height: 40 }}
                ></img>
              </div>
              <div class="flex-column">
                <h6 class=" mb-0">
                  {participantProfile.userName
                    ? participantProfile.userName
                    : participantProfile.firstName}
                </h6>

                {participantProfile.userName && (
                  <Link
                    to={`/${participantProfile.userName}`}
                    className="mb-0 text-decoration-none"
                  >
                    <small class="text-primary">View page</small>
                  </Link>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="d-md-block"></div>
      </div>
      <div className="chat-container mt-2">
        <div className="chat-box d-flex flex-column border rounded">
          {/* {messages &&
          messages.map((message, index) => (
            <div
              key={index}
              className={`d-flex flex-row ${
                message.senderId === user._id
                  ? "justify-content-end"
                  : "justify-content-start"
              } mb-2`}
            >
              {message.senderId !== user._id && (
                <img
                  src={message.photoURL}
                  alt="avatar"
                  className="rounded-circle me-2"
                  width="30"
                  height="30"
                />
              )}
              <div className="d-flex flex-column">
                {message.mediaType && (
                  <div className="mb-2">
                    {message.mediaType === "Photo" ? (
                      <ReactImageGallery
                        imageUrls={[message.media]}
                      ></ReactImageGallery>
                    ) : (
                      <CloudinaryVideoPlayer
                        url={message.media}
                      ></CloudinaryVideoPlayer>
                    )}
                  </div>
                )}
                <div
                  className={`message ${
                    message.senderId === user._id ? "sent" : "received"
                  }`}
                >
                  {message.text}
                </div>
              </div>
              {message.senderId === user._id && (
                <img
                  src={message.photoURL}
                  alt="avatar"
                  className="rounded-circle ms-2"
                  width="30"
                  height="30"
                />
              )}
            </div>
          ))} */}

          {messages &&
            messages.map((message, index) => (
              <div
                key={index}
                className={`message ${
                  message.senderId === user._id ? "sent" : "received"
                }`}
                style={{ overflowWrap: "break-word" }}
              >
                <img
                  src={message.photoURL}
                  alt="avatar"
                  className="rounded-circle me-2"
                  width="30"
                  height="30"
                />
                {message.text}
                {message.media && (
                  <div className="mt-2 d-flex justify-content-center">
                    {message.mediaType === "Photo" ? (
                      <ReactImageGallery
                        imageUrls={[message.media]}
                      ></ReactImageGallery>
                    ) : (
                      <CloudinaryVideoPlayer
                        url={message.media}
                      ></CloudinaryVideoPlayer>
                    )}
                  </div>
                )}
              </div>
            ))}

          <span ref={dummy}></span>
        </div>

        <div class="input-group mt-3">
          <button
            className="btn btn-light rounded-end"
            data-bs-toggle="modal"
            data-bs-target="#uploadMediaToChatModal"
            type="button"
          >
            <i class="bi bi-paperclip"></i>
          </button>

          <input
            type="text"
            className="form-control rounded-start ms-2"
            value={input}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            placeholder="Type a message"
          ></input>

          <button
            className="btn btn-primary text-white"
            onClick={handleSendMessage}
            type="button"
          >
            Send
          </button>
        </div>
        <div class="modal fade" id="uploadMediaToChatModal" tabIndex="-1">
          <div class="modal-dialog modal-dialog-scrollable">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="uploadMediaToChatModalLabel">
                  Send Message
                </h1>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id="uploadMediaToChatModalCloseButton"
                  onClick={() => {
                    setInput("");
                    setMedia("");
                    setMessageText("");
                    setChoosen(false);
                    setMediaType("");
                  }}
                ></button>
              </div>
              <div class="modal-body">
                <div class="mb-3">
                  <label htmlFor="inputMediaType" class="form-label">
                    Message media type
                  </label>
                  <select
                    id="inputMediaType"
                    className="form-select"
                    onChange={(event) => {
                      setChoosen(true);
                      setMediaType(event.target.value);
                    }}
                    value={mediaType}
                  >
                    <option value="" disabled>
                      Choose...
                    </option>
                    <option value="Photo" disabled={choosen}>
                      Photo
                    </option>
                    <option value="Video" disabled={choosen}>
                      Video
                    </option>
                  </select>
                </div>
                {mediaType && (
                  <>
                    <label className="form-label">Media</label>
                    <div class="mb-3 border rounded">
                      <div className="p-3 mt-2">
                        {media ? (
                          <div class="position-relative">
                            {mediaType === "Photo" ? (
                              <>
                                <img
                                  src={media}
                                  className="img-fluid"
                                  alt=""
                                ></img>
                                <div class="position-absolute top-0 end-0">
                                  <span
                                    class="badge text-bg-danger me-2 mt-2"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => setMedia("")}
                                  >
                                    <i class="bi bi-trash"></i>
                                  </span>
                                </div>
                              </>
                            ) : (
                              <div className="d-flex justify-content-center">
                                <CloudinaryVideoPlayer
                                  url={media}
                                ></CloudinaryVideoPlayer>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div>
                            <div className="d-flex justify-content-center">
                              {mediaType === "Photo" ? (
                                <CloudinaryUploadWidget
                                  returnPhoto={setMedia}
                                  folderName={`user/media/`}
                                  className={"btn btn-outline-dark"}
                                  content={<i class="bi bi-plus-lg"></i>}
                                  croppingAspectRatio={16 / 9}
                                  mediaType={"image"}
                                ></CloudinaryUploadWidget>
                              ) : (
                                <CloudinaryUploadWidget
                                  returnPhoto={setMedia}
                                  folderName={`user/media/`}
                                  className={"btn btn-outline-dark"}
                                  content={<i class="bi bi-plus-lg"></i>}
                                  // croppingAspectRatio={1.3}
                                  mediaType={"video"}
                                ></CloudinaryUploadWidget>
                              )}
                            </div>
                            <p class="fw-normal text-primary text-center mt-2">
                              Upload your media here.
                            </p>
                            <div
                              id="mediaHelp"
                              className="form-text text-center"
                            >
                              Photos and videos are best with an aspect ratio of
                              16:9.
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="messageText" className="form-label">
                        Message
                      </label>
                      <textarea
                        className={`form-control ${
                          isMessageValid ? "" : "is-invalid"
                        }`}
                        id="messageText"
                        rows="3"
                        defaultValue={messageText}
                        onBlur={(event) => {
                          setMessageText(event.target.value);
                        }}
                        onChange={() => setIsMessageValid(true)}
                      ></textarea>
                      <div className="invalid-feedback">
                        You must have some message text.
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    setMedia("");
                    setChoosen(false);
                    setMediaType("");
                  }}
                >
                  Close
                </button>
                {/* {loading ? } */}
                {mediaMessageLoading ? (
                  <button class="btn btn-primary text-white" disabled>
                    <span class="spinner-border spinner-border-sm me-2"></span>
                    <span role="status">Send message</span>
                  </button>
                ) : (
                  <button
                    type="button"
                    class="btn btn-primary text-white"
                    onClick={() => sendMediaToChat()}
                  >
                    Send message
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <footer class="footer mt-auto py-3 fixed-bottom">
          <div class="container col-lg-9">
            <div class="input-group mt-2 mb-4">
              <button
                className="btn btn-light rounded-end"
                data-bs-toggle="modal"
                data-bs-target="#uploadMediaToChatModal"
                type="button"
              >
                <i class="bi bi-paperclip"></i>
              </button>

              <input
                type="text"
                className="form-control rounded-start ms-2"
                value={input}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                placeholder="Type a message"
              ></input>

              <button
                className="btn btn-primary text-white"
                onClick={handleSendMessage}
                type="button"
              >
                Send
              </button>
            </div>
          </div>
        </footer> */}
      </div>
    </>
  );
};

export default ChatDetails;

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
// TODO - FIO PRODUCTION
import { firestore, firebase } from "../firebase";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { Link, useNavigate } from "react-router-dom";

const Chats = () => {
  const navigate = useNavigate();
  const [chats, setChats] = useState(null);
  const userLogin = useSelector((state) => state.userLogin);
  const { user } = userLogin;

  const limitText = (text) => {
    if (!text) return "No messages yet";

    const maxLength = 36; // Maximum number of characters
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  const convertTimestampToTime = (timestamp) => {
    if (!timestamp) return;
    const date = timestamp.toDate(); // Convert Firestore Timestamp to JavaScript Date object
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const amOrPm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Convert 24-hour time to 12-hour time
    const formattedMinutes = minutes.toString().padStart(2, "0"); // Add leading zero if minutes < 10
    return `${formattedHours}:${formattedMinutes} ${amOrPm}`;
  };

  useEffect(() => {
    const fetchChats = async () => {
      const chatsRef = firestore.collection("chats");
      const snapshot = await chatsRef.get();
      const chatsData = await Promise.all(
        snapshot.docs.map(async (doc) => {
          const chat = { id: doc.id, ...doc.data() };

          // Only process chats where the logged-in user is a participant
          if (!chat.participants.includes(user._id)) {
            return null; // Skip this chat
          }

          // Fetch the most recent message
          const messagesRef = firestore
            .collection("chats")
            .doc(doc.id)
            .collection("messages")
            .orderBy("createdAt", "desc")
            .limit(1);
          const messageSnapshot = await messagesRef.get();
          chat.recentMessage = messageSnapshot.docs[0]?.data() || null;

          await Promise.all(
            chat.participants.map(async (participantId) => {
              if (participantId !== user._id) {
                const userProfile = await firestore
                  .collection("users")
                  .doc(participantId)
                  .get();
                chat.participantProfile = {
                  id: participantId,
                  ...userProfile.data(),
                };
              }
            })
          );

          // Check for unread messages
          chat.unread =
            chat.recentMessage?.senderId !== user._id &&
            chat.recentMessage?.createdAt?.toMillis() >
              chat[`lastRead_${user._id}`]?.toMillis();

          return chat;
        })
      );

      // Filter out null values
      const validChatsData = chatsData.filter(
        (chat) => chat !== null && chat.recentMessage
      );

      // Sort chats by the most recent message timestamp in descending order
      validChatsData.sort((a, b) => {
        const aTime = a.recentMessage?.createdAt?.toMillis() || 0;
        const bTime = b.recentMessage?.createdAt?.toMillis() || 0;
        return bTime - aTime;
      });

      setChats(validChatsData);
    };

    fetchChats();
  }, [user._id]);

  return (
    <div>
      <h2>Chats</h2>
      <div class="vstack gap-2">
        {!chats && (
          <>
            <div className="border rounded p-2">
              <p class="placeholder-glow mb-1">
                <span class="placeholder col-4"></span>
                <span class="placeholder col-9"></span>
                <span class="placeholder col-12"></span>
              </p>
            </div>
            <div className="border rounded p-2">
              <p class="placeholder-glow mb-1">
                <span class="placeholder col-4"></span>
                <span class="placeholder col-9"></span>
                <span class="placeholder col-12"></span>
              </p>
            </div>
            <div className="border rounded p-2">
              <p class="placeholder-glow mb-1">
                <span class="placeholder col-4"></span>
                <span class="placeholder col-9"></span>
                <span class="placeholder col-12"></span>
              </p>
            </div>
          </>
        )}
        {chats !== null && chats.length === 0 && (
          <div class="p-5 text-center bg-body-tertiary">
            <div class="container py-5">
              <h1 class="text-body-emphasis">
                <i class="bi bi-chat-dots"></i>
              </h1>
              <h1 class="text-body-emphasis">No chats yet</h1>
              <p class="col-lg-8 mx-auto lead">
                Once you have a chats with other users they will show up here.
              </p>
            </div>
          </div>
        )}
        {chats &&
          chats.map((chat) => (
            <div
              class="border rounded p-2 d-flex justify-content-between align-items-center"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(`/chats/${chat.id}`)}
              key={chat.id}
            >
              <div class="d-flex align-items-center">
                <img
                  class="avatar avatar-lg"
                  src={chat.participantProfile.profilePicture}
                  alt="avatar"
                  className="rounded-circle mr-3"
                  width="40"
                  height="40"
                />
                <div class="ms-3">
                  <p class="fw-bold mb-0">
                    {chat.participantProfile.userName
                      ? chat.participantProfile.userName
                      : chat.participantProfile.firstName}
                  </p>
                  <p class="text-muted mb-0 fs-sm">
                    {limitText(chat.recentMessage?.text)}
                  </p>
                </div>
              </div>
              {chat.unread === true ? (
                <span class="badge text-bg-success align-top">New</span>
              ) : (
                <small class="opacity-75 text-nowrap">
                  {convertTimestampToTime(chat.recentMessage?.createdAt)}
                </small>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default Chats;

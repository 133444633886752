import {
  CREATE_POST_FAIL,
  CREATE_POST_REQUEST,
  CREATE_POST_SUCCESS,
  DELETE_POST_FAIL,
  DELETE_POST_REQUEST,
  DELETE_POST_SUCCESS,
  LIKE_POST_FAIL,
  LIKE_POST_REQUEST,
  LIKE_POST_SUCCESS,
  UPDATE_POST_FAIL,
  UPDATE_POST_REQUEST,
  UPDATE_POST_SUCCESS,
} from "../constants/postConstants";

export const postReducer = (
  state = { loading: false, post: null, message: null },
  action
) => {
  switch (action.type) {
    case CREATE_POST_REQUEST:
    case UPDATE_POST_REQUEST:
    case DELETE_POST_REQUEST:
    case LIKE_POST_REQUEST:
      return {
        loading: true,
        post: null,
        message: null,
      };
    case CREATE_POST_SUCCESS:
    case UPDATE_POST_SUCCESS:
    case DELETE_POST_SUCCESS:
    case LIKE_POST_SUCCESS:
      return {
        loading: false,
        post: action.payload.post,
        message: action.payload.message,
      };
    case CREATE_POST_FAIL:
    case UPDATE_POST_FAIL:
    case DELETE_POST_FAIL:
    case LIKE_POST_FAIL:
      return {
        loading: false,
        post: null,
        message: null,
        error: action.payload,
      };

    default:
      return state;
  }
};

import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "bootstrap";

import CloudinaryUploadWidget from "./CloudinaryUploadWidget";
import {
  editCreatorInformation,
  getCreatorPageInformation,
} from "../actions/userActions";

const EditSubscriptionModal = ({ creatorInformation }) => {
  const modalRef = useRef();
  const dispatch = useDispatch();

  const { creatorSubscriptionType, userName } = creatorInformation;
  const [subscriptionType, setSubscriptionType] = useState("");

  const editedCreatorInformation = useSelector(
    (state) => state.editCreatorInformation
  );
  const { loading } = editedCreatorInformation;

  const handleEditProfile = async () => {
    const profileData = {
      creatorSubscriptionType: subscriptionType,
    };
    await dispatch(editCreatorInformation(profileData));
    closeModal();
    dispatch(getCreatorPageInformation(userName));
  };

  const closeModal = () => {
    let modal = document.getElementById(
      "editProfileSubscriptionModalCloseButton"
    );
    modal.click();
  };
  useEffect(() => {
    var myModal = modalRef.current;
    var bsModal = new Modal(myModal);
    setSubscriptionType(creatorSubscriptionType ? creatorSubscriptionType : "");
  }, [creatorInformation]);
  return (
    <div
      class="modal fade"
      id="editSubscriptionModal"
      tabIndex="-1"
      ref={modalRef}
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="editSubscriptionModalLabel">
              Change the subscription for your fans
            </h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="editProfileSubscriptionModalCloseButton"
            ></button>
          </div>
          <div class="modal-body">
            <div class="list-group">
              <label class="list-group-item d-flex gap-2">
                <input
                  class="form-check-input flex-shrink-0"
                  type="radio"
                  name="subscriptionRadios"
                  id="subscriptionRadios1"
                  value="Basic"
                  checked={subscriptionType === "Basic"}
                  onChange={() => setSubscriptionType("Basic")}
                ></input>
                <span>
                  Basic subscribers
                  <small class="d-block text-body-secondary">
                    $5.00 monthly
                  </small>
                  <small class="d-block text-body-secondary">
                    $13.50 3 months (10% off)
                  </small>
                  <small class="d-block text-body-secondary">
                    $25.50 6 months (15% off)
                  </small>
                  <small class="d-block text-body-secondary">
                    $48.00 12 months (20% off)
                  </small>
                </span>
              </label>
              <label class="list-group-item d-flex gap-2">
                <input
                  class="form-check-input flex-shrink-0"
                  type="radio"
                  name="subscriptionRadios"
                  id="subscriptionRadios2"
                  value="Pro"
                  checked={subscriptionType === "Pro"}
                  onChange={() => setSubscriptionType("Pro")}
                ></input>
                <span>
                  Pro subscribers
                  <small class="d-block text-body-secondary">
                    $10.00 monthly
                  </small>
                  <small class="d-block text-body-secondary">
                    $27.00 3 months (10% off)
                  </small>
                  <small class="d-block text-body-secondary">
                    $51.00 6 months (15% off)
                  </small>
                  <small class="d-block text-body-secondary">
                    $96.00 12 months (20% off)
                  </small>
                </span>
              </label>
              <label class="list-group-item d-flex gap-2">
                <input
                  class="form-check-input flex-shrink-0"
                  type="radio"
                  name="subscriptionRadios"
                  id="subscriptionRadios3"
                  value="Premium"
                  checked={subscriptionType === "Premium"}
                  onChange={() => setSubscriptionType("Premium")}
                ></input>
                <span>
                  Premium subscribers
                  <small class="d-block text-body-secondary">
                    $20.00 monthly
                  </small>
                  <small class="d-block text-body-secondary">
                    $54.00 3 months (10% off)
                  </small>
                  <small class="d-block text-body-secondary">
                    $102.00 6 months (15% off)
                  </small>
                  <small class="d-block text-body-secondary">
                    $192.00 12 months (20% off)
                  </small>
                </span>
              </label>
            </div>
            <p className="fw-light mt-3">
              <small>
                If you need specific pricing plans please reach out to us.
              </small>
            </p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            {loading ? (
              <button class="btn btn-primary text-white" disabled>
                <span class="spinner-border spinner-border-sm me-2"></span>
                <span role="status">Update subscription price</span>
              </button>
            ) : (
              <button
                type="button"
                class="btn btn-primary text-white"
                onClick={() => handleEditProfile()}
              >
                Update subscription price
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditSubscriptionModal;

import React from "react";
import Footer from "../components/Footer";

const TermsScreen = () => {
  return (
    <div class="container mt-5">
      {/* <h1 class="mb-4">Terms and Conditions</h1>
      <p>Last updated: May 29, 2024</p>

      <section>
        <h2>1. Introduction</h2>
        <p>
          Welcome to SoloStreamer. These Terms and Conditions ("Terms", "Terms
          and Conditions") govern your relationship with the SoloStreamer
          website (the "Service") operated by SoloStreamer ("us", "we", or
          "our").
        </p>
        <p>
          Please read these Terms and Conditions carefully before using our
          Service. Your access to and use of the Service is conditioned upon
          your acceptance of and compliance with these Terms. These Terms apply
          to all visitors, users, and others who wish to access or use the
          Service.
        </p>
      </section>

      <section>
        <h2>2. Accounts</h2>
        <p>
          When you create an account with us, you guarantee that you are above
          the age of 18, and that the information you provide us is accurate,
          complete, and current at all times. Inaccurate, incomplete, or
          obsolete information may result in the immediate termination of your
          account on the Service.
        </p>
        <p>
          You are responsible for maintaining the confidentiality of your
          account and password, including but not limited to the restriction of
          access to your computer and/or account. You agree to accept
          responsibility for any and all activities or actions that occur under
          your account and/or password, whether your password is with our
          Service or a third-party service. You must notify us immediately upon
          becoming aware of any breach of security or unauthorized use of your
          account.
        </p>
      </section>

      <section>
        <h2>3. Fees and Payments</h2>
        <h3>3.1 Subscription Fees</h3>
        <p>
          Users will pay a 2.9% + 37 cents card fee for subscriptions.
          SoloStreamer will take 10% of the subscription payments, including
          these stripe fees.
        </p>

        <h3>3.2 Tips</h3>
        <p>
          When a user tips a creator, the creator will pay a 2.9% + 30 cents
          card fee. SoloStreamer will take 10% of the tips, including these
          stripe fees.
        </p>

        <h3>3.3 Payouts</h3>
        <p>
          All funds will accumulate in a Stripe "balance" where users can then
          choose to pay out the funds they make. There is a $2 + 1% of all funds
          charge when a creator decides to pay out.
        </p>
      </section>

      <section>
        <h2>4. Content</h2>
        <p>
          Our Service allows you to post, link, store, share, and otherwise make
          available certain information, text, graphics, videos, or other
          material ("Content"). You are responsible for the Content that you
          post on or through the Service, including its legality, reliability,
          and appropriateness.
        </p>
        <p>
          By posting Content on or through the Service, You represent and
          warrant that: (i) the Content is yours (you own it) and/or you have
          the right to use it and the right to grant us the rights and license
          as provided in these Terms, and (ii) that the posting of your Content
          on or through the Service does not violate the privacy rights,
          publicity rights, copyrights, contract rights, or any other rights of
          any person or entity. We reserve the right to terminate the account of
          anyone found to be infringing on a copyright.
        </p>
      </section>

      <section>
        <h2>5. Termination</h2>
        <p>
          We may terminate or suspend your account and bar access to the Service
          immediately, without prior notice or liability, under our sole
          discretion, for any reason whatsoever and without limitation,
          including but not limited to a breach of the Terms.
        </p>
        <p>
          If you wish to terminate your account, you may simply discontinue
          using the Service.
        </p>
        <p>
          All provisions of the Terms which by their nature should survive
          termination shall survive termination, including, without limitation,
          ownership provisions, warranty disclaimers, indemnity, and limitations
          of liability.
        </p>
      </section>

      <section>
        <h2>6. Changes</h2>
        <p>
          We reserve the right, at our sole discretion, to modify or replace
          these Terms at any time. If a revision is material, we will provide at
          least 30 days' notice prior to any new terms taking effect. What
          constitutes a material change will be determined at our sole
          discretion.
        </p>
        <p>
          By continuing to access or use our Service after any revisions become
          effective, you agree to be bound by the revised terms. If you do not
          agree to the new terms, you are no longer authorized to use the
          Service.
        </p>
      </section>

      <section>
        <h2>7. Contact Us</h2>
        <p>
          If you have any questions about these Terms, please contact us at
          support@solostreamer.com.
        </p>
      </section> */}
      <h1 class="mb-4">Terms and Conditions</h1>
      <p>Last updated: May 29, 2024</p>

      <h2>1. Introduction</h2>
      <p>
        Welcome to SoloStreamer. These Terms and Conditions ("Terms", "Terms and
        Conditions") govern your relationship with the SoloStreamer website (the
        "Service") operated by SoloStreamer ("us", "we", or "our").
      </p>
      <p>
        Please read these Terms and Conditions carefully before using our
        Service. Your access to and use of the Service is conditioned upon your
        acceptance of and compliance with these Terms. These Terms apply to all
        visitors, users, and others who wish to access or use the Service.
      </p>

      <h2>2. Accounts</h2>
      <p>
        When you create an account with us, you guarantee that you are above the
        age of 18, and that the information you provide us is accurate,
        complete, and current at all times. Inaccurate, incomplete, or obsolete
        information may result in the immediate termination of your account on
        the Service.
      </p>
      <p>
        You are responsible for maintaining the confidentiality of your account
        and password, including but not limited to the restriction of access to
        your computer and/or account. You agree to accept responsibility for any
        and all activities or actions that occur under your account and/or
        password, whether your password is with our Service or a third-party
        service. You must notify us immediately upon becoming aware of any
        breach of security or unauthorized use of your account.
      </p>

      <h2>3. Fees and Payments</h2>
      <h3>3.1 Subscription Fees</h3>
      <p>
        Users will pay a 2.9% + 37 cents card fee for subscriptions.
        SoloStreamer will take 10% of the subscription payments, including these
        Stripe fees.
      </p>
      <h3>3.2 Tips</h3>
      <p>
        When a user tips a creator, the creator will pay a 2.9% + 30 cents card
        fee. SoloStreamer will take 10% of the tips, including these Stripe
        fees.
      </p>
      <h3>3.3 Payouts</h3>
      <p>
        All funds will accumulate in a Stripe "balance" where users can then
        choose to pay out the funds they make. There is a $2 + 1% of all funds
        charge when a creator decides to pay out.
      </p>

      <h2>4. Content</h2>
      <p>
        Our Service allows you to post, link, store, share, and otherwise make
        available certain information, text, graphics, videos, or other material
        ("Content"). You are responsible for the Content that you post on or
        through the Service, including its legality, reliability, and
        appropriateness.
      </p>
      <p>
        By posting Content on or through the Service, You represent and warrant
        that: (i) the Content is yours (you own it) and/or you have the right to
        use it and the right to grant us the rights and license as provided in
        these Terms, and (ii) that the posting of your Content on or through the
        Service does not violate the privacy rights, publicity rights,
        copyrights, contract rights, or any other rights of any person or
        entity. We reserve the right to terminate the account of anyone found to
        be infringing on a copyright.
      </p>

      <h2>5. Termination</h2>
      <p>
        We may terminate or suspend your account and bar access to the Service
        immediately, without prior notice or liability, under our sole
        discretion, for any reason whatsoever and without limitation, including
        but not limited to a breach of the Terms.
      </p>
      <p>
        If you wish to terminate your account, you may simply discontinue using
        the Service.
      </p>
      <p>
        All provisions of the Terms which by their nature should survive
        termination shall survive termination, including, without limitation,
        ownership provisions, warranty disclaimers, indemnity, and limitations
        of liability.
      </p>

      <h2>6. Limitation of Liability</h2>
      <p>
        To the maximum extent permitted by applicable law, in no event shall
        SoloStreamer, its affiliates, agents, directors, employees, suppliers,
        or licensors be liable for any indirect, incidental, special,
        consequential, or punitive damages, including without limitation, loss
        of profits, data, use, goodwill, or other intangible losses, resulting
        from (i) your access to or use of or inability to access or use the
        Service; (ii) any conduct or content of any third party on the Service;
        (iii) any content obtained from the Service; and (iv) unauthorized
        access, use, or alteration of your transmissions or content, whether
        based on warranty, contract, tort (including negligence), or any other
        legal theory, whether or not we have been informed of the possibility of
        such damage, and even if a remedy set forth herein is found to have
        failed of its essential purpose.
      </p>
      <p>
        We do not guarantee the safety or security of your content. Users and
        creators are solely responsible for ensuring their content does not
        violate any laws or third-party rights. We are not liable for any
        unauthorized access, use, or disclosure of your content or personal
        information.
      </p>

      <h2>7. Indemnification</h2>
      <p>
        You agree to defend, indemnify, and hold harmless SoloStreamer and its
        licensee and licensors, and their employees, contractors, agents,
        officers, and directors, from and against any and all claims, damages,
        obligations, losses, liabilities, costs, or debt, and expenses
        (including but not limited to attorney's fees), resulting from or
        arising out of a) your use and access of the Service, by you or any
        person using your account and password; b) a breach of these Terms, or
        c) Content posted on the Service.
      </p>

      <h2>8. Changes</h2>
      <p>
        We reserve the right, at our sole discretion, to modify or replace these
        Terms at any time. If a revision is material, we will provide at least
        30 days' notice prior to any new terms taking effect. What constitutes a
        material change will be determined at our sole discretion.
      </p>
      <p>
        By continuing to access or use our Service after any revisions become
        effective, you agree to be bound by the revised terms. If you do not
        agree to the new terms, you are no longer authorized to use the Service.
      </p>
      <Footer></Footer>
    </div>
  );
};

export default TermsScreen;

import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { stripe_public_key } from "../config/env";

import CheckoutForm from "../components/CheckoutForm";
import { getCreateSubscriptionScreenInformation } from "../actions/subscriptionActions";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(stripe_public_key);

const CreateSubscriptionScreen = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const searchParams = new URLSearchParams(location.search);
  //   const creatorUsername = searchParams.get("creatorUsername");
  const creatorUsername = params.creatorUsername;
  const priceId = params.priceId;

  const userLogin = useSelector((state) => state.userLogin);
  const { isAuthenticated, loading: userLoading } = userLogin;
  const subscriptionCreationScreen = useSelector(
    (state) => state.subscriptionCreationScreen
  );
  const {
    loading: subscriptionLoading,
    creatorInformation,
    subscriptionPlan,
  } = subscriptionCreationScreen;

  useEffect(() => {
    if (isAuthenticated === true) {
      dispatch(
        getCreateSubscriptionScreenInformation(creatorUsername, priceId)
      );
    }
    if (isAuthenticated === false) {
      navigate(`/login?redirect=${location.pathname}`);
    }
  }, [isAuthenticated]);
  return (
    <>
      {(userLoading || subscriptionLoading) && (
        <>
          <div className="row mx-3 justify-content-center">
            <div className="col-lg-6 mt-0 p-2">
              <div class="card mx-1">
                <div class="card-body">
                  <h5 class="card-title placeholder-glow">
                    <span class="placeholder col-6"></span>
                  </h5>
                  <p class="card-text placeholder-glow">
                    <span class="placeholder col-7"></span>
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-6"></span>
                    <span class="placeholder col-8"></span>
                  </p>
                  <a
                    class="btn btn-primary disabled placeholder col-6"
                    aria-disabled="true"
                  ></a>
                </div>
              </div>
            </div>
          </div>
          <div className="row mx-3 justify-content-center">
            <div className="col-lg-6">
              <div class="my-3">
                <div class="vstack gap-3 px-0">
                  <div className="border rounded p-2">
                    <p class="placeholder-glow mb-1">
                      <span class="placeholder col-4"></span>
                      <span class="placeholder col-9"></span>
                      <span class="placeholder col-12"></span>
                    </p>
                  </div>
                  <div className="border rounded p-2">
                    <p class="placeholder-glow mb-1">
                      <span class="placeholder col-4"></span>
                      <span class="placeholder col-9"></span>
                      <span class="placeholder col-12"></span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {!subscriptionLoading && isAuthenticated && creatorInformation && (
        <div className="container justify-content-center col-md-5 my-4">
          <h6 className="text-muted mt-4">CREATOR INFORMATION</h6>
          <div className="border rounded">
            <div className="p-3">
              <div className="d-flex align-items-center">
                <div className="me-2">
                  <img
                    src={creatorInformation.profilePhoto}
                    class="bd-placeholder-img rounded-circle border-white"
                    alt=""
                    style={{ width: 50, height: 50 }}
                  ></img>
                </div>
                <div className="flex-column ms-2">
                  <p className="mb-0">
                    <small>
                      Creator since {creatorInformation.formattedDate}
                    </small>
                  </p>
                  <h5 className=" mb-0">@{creatorInformation.userName}</h5>
                  <Link
                    className=" mb-0 text-decoration-none"
                    to={`/${creatorInformation.userName}`}
                  >
                    <small className="text-primary">View page</small>
                  </Link>
                </div>
              </div>
              <hr></hr>
              <p class="mb-1">
                <i class="bi bi-check-lg text-primary"></i> Enjoy full access to
                this user's exclusive content.
              </p>
              <p class="mb-1">
                <i class="bi bi-check-lg text-primary"></i> Cancel your
                subscription hassle-free anytime.
              </p>
              <p class="mb-1">
                <i class="bi bi-check-lg text-primary"></i> Gain early access to
                new releases and updates from this user.
              </p>
            </div>
          </div>
          <h6 className="text-muted mt-3">SUBSCRIPTION PLAN</h6>

          <div className="border rounded">
            <div className="p-3">
              <p class="mb-1">
                <strong>{subscriptionPlan.name}</strong>
              </p>
              <p class="mb-0">
                ${(subscriptionPlan.basePrice / 100).toFixed(2)} base
                subscription
              </p>
              <p class="mb-0">
                ${(subscriptionPlan.feePrice / 100).toFixed(2)} processing fee
              </p>
              <p class="mb-0">+ state sales tax (if applicable)</p>
            </div>
          </div>

          <h6 className="text-muted mt-3">PAYMENT INFORMATION</h6>
          <Elements
            stripe={stripePromise}
            options={{
              //   // passing the SetupIntent's client secret
              //   clientSecret: clientSecret,
              // This is just filler??
              mode: "subscription",
              amount: 1099,
              currency: "usd",
              contry: "US",
              // setup_future_usage: "off_session",
              // Fully customizable with appearance API.
              appearance: {
                /*...*/
              },
            }}
          >
            <CheckoutForm creatorUsername={creatorUsername} priceId={priceId} />
          </Elements>
        </div>
      )}
    </>
  );
};

export default CreateSubscriptionScreen;

import React, { useState, useEffect, useRef } from "react";
import { Modal } from "bootstrap";
import { useSelector, useDispatch } from "react-redux";

import CloudinaryUploadWidget from "./CloudinaryUploadWidget";

import {
  editCreatorInformation,
  getCreatorPageInformation,
} from "../actions/userActions";

const EditProfileModal = ({ creatorInformation }) => {
  const modalRef = useRef();
  const dispatch = useDispatch();

  const {
    profilePhoto: creatorProfilePhoto,
    profileBio: creatorBio,
    twitter,
    instagram,
    tiktok,
    userName,
  } = creatorInformation;

  const editedCreatorInformation = useSelector(
    (state) => state.editCreatorInformation
  );
  const { loading } = editedCreatorInformation;

  const [profilePhoto, setProfilePhoto] = useState("");
  const [profileBio, setProfileBio] = useState("");
  const [profileInstagram, setProfileInstagram] = useState("");
  const [profileTwitter, setProfileTwitter] = useState("");
  const [profileTiktok, setProfileTiktok] = useState("");

  const handleEditProfile = async () => {
    const profileData = {
      profileBio: profileBio,
      profilePhoto: profilePhoto,
      instagram: profileInstagram,
      twitter: profileTwitter,
      tiktok: profileTiktok,
    };
    await dispatch(editCreatorInformation(profileData));
    closeModal();
    dispatch(getCreatorPageInformation(userName));
  };

  const closeModal = () => {
    let modal = document.getElementById("editProfileModalCloseButton");
    modal.click();
  };

  useEffect(() => {
    var myModal = modalRef.current;
    var bsModal = new Modal(myModal);
    setProfilePhoto(creatorProfilePhoto ? creatorProfilePhoto : "");
    setProfileBio(creatorBio ? creatorBio : "");
    setProfileInstagram(instagram ? instagram : "");
    setProfileTwitter(twitter ? twitter : "");
    setProfileTiktok(tiktok ? tiktok : "");
  }, [creatorInformation]);

  return (
    <div class="modal fade" id="editProfileModal" tabIndex="-1" ref={modalRef}>
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="editProfileModalLabel">
              Edit Profile
            </h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="editProfileModalCloseButton"
            ></button>
          </div>
          <div class="modal-body">
            <form>
              <div className="mb-3">
                <img
                  src={profilePhoto}
                  className="bd-placeholder-img rounded-circle mx-auto"
                  alt=""
                  style={{ width: 75, height: 75 }}
                ></img>
                <CloudinaryUploadWidget
                  returnPhoto={setProfilePhoto}
                  folderName={`solostreamerProfilePictures/user`}
                  className={"btn btn-light ms-3"}
                  content={"Change profile photo"}
                  mediaType={"image"}
                  croppingAspectRatio={1}
                ></CloudinaryUploadWidget>
              </div>

              <div className="mb-3">
                <label htmlFor="profileBio" className="form-label">
                  Seller Bio
                </label>
                <textarea
                  className="form-control"
                  id="profileBio"
                  rows="3"
                  defaultValue={profileBio}
                  onBlur={(event) => setProfileBio(event.target.value)}
                ></textarea>
              </div>

              <div className="mb-3">
                <div className="input-group">
                  <span className="input-group-text">
                    <i class="bi bi-tiktok"></i>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={profileTiktok}
                    onBlur={(event) => setProfileTiktok(event.target.value)}
                  ></input>
                </div>
                <div id="emailHelp" className="form-text">
                  If you would like users to know your Tiktok.
                </div>
              </div>
              <div className="mb-3">
                <div className="input-group">
                  <span className="input-group-text">
                    <i class="bi bi-twitter"></i>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={profileTwitter}
                    onBlur={(event) => setProfileTwitter(event.target.value)}
                  ></input>
                </div>
                <div id="emailHelp" className="form-text">
                  If you would like users to know your Twitter/X.
                </div>
              </div>
              <div className="mb-3">
                <div className="input-group">
                  <span className="input-group-text">
                    <i class="bi bi-instagram"></i>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={profileInstagram}
                    onBlur={(event) => setProfileInstagram(event.target.value)}
                  ></input>
                </div>
                <div id="emailHelp" className="form-text">
                  If you would like users to know your Instagram.
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            {loading ? (
              <button class="btn btn-primary text-white" disabled>
                <span class="spinner-border spinner-border-sm me-2"></span>
                <span role="status">Save changes</span>
              </button>
            ) : (
              <button
                type="button"
                class="btn btn-primary text-white"
                onClick={() => handleEditProfile()}
              >
                Save changes
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfileModal;

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../actions/userActions";

const NavigationOffcanvas = () => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { isAuthenticated, user, isCreator, loading } = userLogin;

  const closeOffCanvas = () => {
    let closeCanvas = document.getElementById("NavigationOffcanvasButton");
    closeCanvas.click();
  };

  useEffect(() => {}, [user]);

  return (
    <div
      class="offcanvas offcanvas-end "
      tabIndex="-10"
      id="navigationOffcanvas"
      style={{ width: 280 }}
    >
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="navigationOffcanvasLabel">
          Navigate
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          id="NavigationOffcanvasButton"
        ></button>
      </div>
      <div class="offcanvas-body pt-0">
        <hr></hr>
        <ul class="nav nav-pills flex-column mb-auto">
          <li class="nav-item">
            <Link
              to="/"
              class="nav-link link-body-emphasis"
              onClick={closeOffCanvas}
            >
              <i class="bi bi-house me-2"></i>
              Home
            </Link>
          </li>
          {loading && (
            <>
              <li>
                <Link
                  to="/signup"
                  class="nav-link link-body-emphasis"
                  onClick={closeOffCanvas}
                >
                  <i class="bi bi-lightbulb me-2"></i>
                  Become a creator
                </Link>
              </li>
              <li>
                <Link
                  to="/login"
                  class="nav-link link-body-emphasis"
                  onClick={closeOffCanvas}
                >
                  <i class="bi bi-box-arrow-in-right me-2"></i>
                  Log in
                </Link>
              </li>
            </>
          )}
          {isAuthenticated === false && (
            <>
              <li>
                <Link
                  to="/signup"
                  class="nav-link link-body-emphasis"
                  onClick={closeOffCanvas}
                >
                  <i class="bi bi-lightbulb me-2"></i>
                  Become a creator
                </Link>
              </li>
              <li>
                <Link
                  to="/login"
                  class="nav-link link-body-emphasis"
                  onClick={closeOffCanvas}
                >
                  <i class="bi bi-box-arrow-in-right me-2"></i>
                  Log in
                </Link>
              </li>
            </>
          )}
          {isAuthenticated === true && !isCreator && (
            <>
              <li>
                <Link
                  to="/subscriptions"
                  class="nav-link link-body-emphasis"
                  onClick={closeOffCanvas}
                >
                  <i class="bi bi-table me-2"></i>
                  My subscriptions
                </Link>
              </li>
              <li>
                <Link
                  to="/chats"
                  class="nav-link link-body-emphasis"
                  onClick={closeOffCanvas}
                >
                  <i class="bi bi-chat-dots me-2"></i>
                  My chats
                </Link>
              </li>
              <li>
                <Link
                  to="/signup"
                  class="nav-link link-body-emphasis"
                  onClick={closeOffCanvas}
                >
                  <i class="bi bi-lightbulb me-2"></i>
                  Become a creator
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  class="nav-link link-body-emphasis"
                  onClick={() => dispatch(logout())}
                >
                  <i class="bi bi-box-arrow-right me-2"></i>
                  Log out
                </Link>
              </li>
            </>
          )}
          {isAuthenticated === true && isCreator && (
            <>
              <li>
                <Link
                  to="/subscriptions"
                  class="nav-link link-body-emphasis"
                  onClick={closeOffCanvas}
                >
                  <i class="bi bi-table me-2"></i>
                  My subscriptions
                </Link>
              </li>
              <li>
                <Link
                  to="/chats"
                  class="nav-link link-body-emphasis"
                  onClick={closeOffCanvas}
                >
                  <i class="bi bi-chat-dots me-2"></i>
                  My chats
                </Link>
              </li>
              <li>
                <Link
                  to={"/" + user.userName}
                  class="nav-link link-body-emphasis"
                  onClick={closeOffCanvas}
                >
                  <i class="bi bi-person me-2"></i>
                  My profile page
                </Link>
              </li>
              <li>
                <Link
                  to={"/dashboard/" + user.userName}
                  class="nav-link link-body-emphasis"
                  onClick={closeOffCanvas}
                >
                  <i class="bi bi-speedometer2 me-2"></i>
                  My dashboard
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  class="nav-link link-body-emphasis"
                  onClick={() => dispatch(logout())}
                >
                  <i class="bi bi-box-arrow-right me-2"></i>
                  Log out
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  );
};

export default NavigationOffcanvas;

import { firebase, firestore } from "../firebase";

// Initialize Firebase
// const firestore = firebase.firestore();

const getOrCreateUserProfile = async (userId, profileData) => {
  const userRef = firestore.collection("users").doc(userId);
  const userDoc = await userRef.get();

  if (!userDoc.exists) {
    await userRef.set(profileData);
    return { id: userId, ...profileData };
  } else {
    return { id: userId, ...userDoc.data() };
  }
};

const findOrCreateChat = async (user, creatorInformation, navigate) => {
  const userId = user._id;
  const participantId = creatorInformation._id;

  if (!userId || !participantId) {
    console.error("User ID or Participant ID is missing.");
    alert("User ID or Participant ID is missing.");
    return;
  }

  if (userId === participantId) {
    console.error("Cannot create a chat with oneself.");
    alert("Cannot create a chat with oneself.");
    return;
  }

  // Ensure both user profiles exist
  const userProfileData = {
    id: userId,
    firstName: user.firstName,
    lastName: user.lastName,
    userName: user.userName ? user.userName : "",
    profilePicture: user.profilePhoto,
  };
  const participantProfileData = {
    id: participantId,
    firstName: creatorInformation.firstName,
    lastName: creatorInformation.lastName,
    userName: creatorInformation.userName ? creatorInformation.userName : "",
    profilePicture: creatorInformation.profilePhoto,
  };
  await getOrCreateUserProfile(userId, userProfileData);
  await getOrCreateUserProfile(participantId, participantProfileData);

  const chatsRef = firebase.firestore().collection("chats");
  let chat = await chatsRef
    .where("participants", "array-contains", userId)
    .get();

  let chatDoc;

  chat.forEach((doc) => {
    const data = doc.data();
    if (data.participants.includes(participantId)) {
      chatDoc = doc;
    }
  });

  // If no chat is found, create a new one
  if (!chatDoc) {
    const chatData = {
      participants: [userId, participantId],
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    };
    chatData[`lastRead_${userId}`] =
      firebase.firestore.FieldValue.serverTimestamp();
    chatData[`lastRead_${participantId}`] =
      firebase.firestore.FieldValue.serverTimestamp();

    const newChatRef = await chatsRef.add(chatData);
    chatDoc = await newChatRef.get();
  }

  navigate(`/chats/${chatDoc.id}`);
};

// const findOrCreateChat = async (user, creatorInformation, navigate) => {
//   const userId = user._id;
//   const participantId = creatorInformation._id;
//   if (!userId || !participantId) {
//     return;
//   }

//   // Ensure both user profiles exist
//   const userProfileData = {
//     id: userId,
//     firstName: user.firstName,
//     lastName: user.lastName,
//     userName: user.userName ? user.userName : "",
//     profilePicture: user.profilePhoto,
//   };
//   const participantProfileData = {
//     id: participantId,
//     firstName: creatorInformation.firstName,
//     lastName: creatorInformation.lastName,
//     userName: creatorInformation.userName ? creatorInformation.userName : "",
//     profilePicture: creatorInformation.profilePhoto,
//   };
//   await getOrCreateUserProfile(userId, userProfileData);
//   await getOrCreateUserProfile(participantId, participantProfileData);

//   const chatsRef = firestore.collection("chats");
//   let chat = await chatsRef
//     .where("participants", "array-contains", userId)
//     .get();

//   let chatDoc;

//   chat.forEach((doc) => {
//     const data = doc.data();
//     if (data.participants.includes(participantId)) {
//       chatDoc = doc;
//     }
//   });

//   // If no chat is found, create a new one
//   if (!chatDoc) {
//     const chatData = {
//       participants: [userId, participantId],
//       createdAt: firebase.firestore.FieldValue.serverTimestamp(),
//     };
//     chatData[`lastRead_${userId}`] =
//       firebase.firestore.FieldValue.serverTimestamp();
//     chatData[`lastRead_${participantId}`] =
//       firebase.firestore.FieldValue.serverTimestamp();

//     const newChatRef = await chatsRef.add(chatData);
//     chatDoc = await newChatRef.get();
//   }

//   navigate(`/chats/${chatDoc.id}`);
// };

export { getOrCreateUserProfile, findOrCreateChat };

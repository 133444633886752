import React from "react";
import { useSelector } from "react-redux";
import ToastAlert from "./ToastAlert";

const AlertsContainer = () => {
  const user = useSelector((state) => state.userLogin);
  const { error: userErrorMessage } = user;
  const createdPost = useSelector((state) => state.post);
  const { message: createdPostMessage, error: createdPostError } = createdPost;
  const editCreatorInformation = useSelector(
    (state) => state.editCreatorInformation
  );
  const {
    message: editedCreatorProfileMessage,
    error: editedCreatorProfileError,
  } = editCreatorInformation;
  const cancelSubscription = useSelector((state) => state.cancelSubscription);
  const {
    message: userCancelSubscriptionMessage,
    error: userCancelSubscriptionError,
  } = cancelSubscription;
  const creatorPayout = useSelector((state) => state.creatorPayout);
  const { message: creatorPayoutMessage, error: creatorPayoutError } =
    creatorPayout;
  return (
    <div className="toast-container position-fixed top-0 end-0 p-4">
      {userCancelSubscriptionMessage && (
        <ToastAlert
          message={userCancelSubscriptionMessage}
          header={"User Subscription"}
        ></ToastAlert>
      )}
      {userCancelSubscriptionError && (
        <ToastAlert
          message={userCancelSubscriptionError}
          header={"Cancel Subscription Error"}
        ></ToastAlert>
      )}
      {creatorPayoutMessage && (
        <ToastAlert
          message={creatorPayoutMessage}
          header={"Payout Information"}
        ></ToastAlert>
      )}
      {creatorPayoutError && (
        <ToastAlert
          message={creatorPayoutError}
          header={"Payout Information"}
        ></ToastAlert>
      )}
      {userErrorMessage && (
        <ToastAlert
          message={userErrorMessage}
          header={"User Error"}
        ></ToastAlert>
      )}
      {editedCreatorProfileMessage && (
        <ToastAlert
          message={editedCreatorProfileMessage}
          header={"Creator profile updated"}
        ></ToastAlert>
      )}
      {editedCreatorProfileError && (
        <ToastAlert
          message={editedCreatorProfileError}
          header={"Creator profile error"}
        ></ToastAlert>
      )}
      {createdPostError && (
        <ToastAlert
          message={createdPostError}
          header={"Post error"}
        ></ToastAlert>
      )}
      {createdPostMessage && (
        <ToastAlert
          message={createdPostMessage}
          header={"Post notification"}
        ></ToastAlert>
      )}
    </div>
  );
};

export default AlertsContainer;

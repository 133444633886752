import React from "react";
import Navbar from "../components/Navbar";
import { Link } from "react-router-dom";
import CloudinaryVideoPlayer from "../components/CloudinaryVideoPlayer";
import CloudinaryGallery from "../components/CloudinaryGallery";
import ReactImageGallery from "../components/ReactImageGallery";
import Footer from "../components/Footer";
const MainScreen = () => {
  const photos = [
    "https://res.cloudinary.com/dx4nlfeak/image/upload/v1714250366/IMG_0531_k7ow9t.jpg",
    "https://res.cloudinary.com/dx4nlfeak/image/upload/v1713920018/user/media/nnrwur2htsefftufzxad.jpg",
    "https://res.cloudinary.com/dx4nlfeak/image/upload/v1713653855/user/media/nrdat9hmrhjyjvrkhaip.jpg",
  ];
  return (
    <>
      <section>
        <div className="py-4 mb-4 main-page-cover">
          <div className="py-5 d-flex justify-content-center">
            <div className="container col-md-9">
              <h1>Earn More, Create More: Join SoloStreamer Today!</h1>
              <h5 className="col-md-9 fw-light mb-4">
                Are you tired of hefty platform fees eating into your
                hard-earned income? Look no further! SoloStreamer is here to
                revolutionize the way creators monetize their content. Just like
                OnlyFans, but better.
              </h5>
              <Link to="/signup" class="btn btn-primary text-white py-2 px-3">
                BECOME A CREATOR
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="d-none d-md-flex justify-content-center">
          {/* <div style={{ width: 200 }}>
            <CloudinaryVideoPlayer url="https://res.cloudinary.com/dx4nlfeak/video/upload/v1713653882/user/media/c75tyuuyukdlgd283nke.mp4"></CloudinaryVideoPlayer>
          </div> */}
          {/* 308Jodyway! is pass for new instagram. Need to change email and phone soon. */}
          <div className="container col-md-9">
            <div class="row ">
              <div class="col-md-4 d-flex flex-column gap-2">
                <div
                  class="feature-icon-small d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-4 rounded-3"
                  style={{ width: 40 }}
                >
                  <i class="bi bi-currency-dollar text-white"></i>
                </div>
                <h4 class="fw-semibold mb-0 text-body-emphasis">
                  Maximize Your Earnings
                </h4>
                <p class="text-body-secondary">
                  Say goodbye to high platform fees! At SoloStreamer, we’ve cut
                  our fees to just 10%, compared to OnlyFans’ 20%. More money in
                  your pocket means more freedom to create.
                </p>
              </div>
              <div class="col-md-4 d-flex flex-column gap-2">
                <div
                  class="feature-icon-small d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-4 rounded-3"
                  style={{ width: 40 }}
                >
                  <i class="bi bi-bag-check text-white"></i>
                </div>
                <h4 class="fw-semibold mb-0 text-body-emphasis">
                  Simple and Seamless
                </h4>
                <p class="text-body-secondary">
                  Our platform is designed with you in mind. Set up your
                  profile, upload content, and connect with your fans in just a
                  few clicks. User-friendly and intuitive, SoloStreamer makes
                  monetization effortless.
                </p>
              </div>

              <div class="col-md-4 d-flex flex-column gap-2">
                <div
                  class="feature-icon-small d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-4 rounded-3"
                  style={{ width: 40 }}
                >
                  <i class="bi bi-shield-check text-white"></i>
                </div>
                <h4 class="fw-semibold mb-0 text-body-emphasis">
                  Safe and Secure
                </h4>
                <p class="text-body-secondary">
                  Your privacy is our top priority. With top-notch security
                  measures, SoloStreamer ensures your content remains safe and
                  secure. Focus on creating while we handle the rest.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div class="row g-4 py-2 row-cols-1 row-cols-lg-3 d-md-none">
            <div class="col d-flex align-items-start">
              <div class="d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-4 rounded-3  me-3">
                <i class="bi bi-currency-dollar text-white p-1 px-2"></i>
              </div>
              <div>
                <h4 class="fw-semibold  text-body-emphasis">
                  Maximize Your Earnings
                </h4>
                <p class="text-body-secondary">
                  Say goodbye to high platform fees! At SoloStreamer, we’ve cut
                  our fees to just 10%, compared to OnlyFans’ 20%. More money in
                  your pocket means more freedom to create.
                </p>
              </div>
            </div>
            <div class="col d-flex align-items-start">
              <div class="  d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-4 rounded-3  me-3">
                <i class="bi bi-bag-check text-white p-1 px-2"></i>
              </div>
              <div>
                <h4 class="fw-semibold text-body-emphasis">
                  Simple and Seamless
                </h4>
                <p class="text-body-secondary">
                  Our platform is designed with you in mind. Set up your
                  profile, upload content, and connect with your fans in just a
                  few clicks. User-friendly and intuitive, SoloStreamer makes
                  monetization effortless.
                </p>
              </div>
            </div>

            <div class="col d-flex align-items-start">
              <div class="d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-4 rounded-3  me-3">
                <i class="bi bi-shield-check text-white p-1 px-2"></i>
              </div>
              <div>
                <h4 class="fw-semibold text-body-emphasis">Safe and Secure</h4>
                <p className="text-body-secondary">
                  Your privacy is our top priority. With top-notch security
                  measures, SoloStreamer ensures your content remains safe and
                  secure. Focus on creating while we handle the rest.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
      {/* <CloudinaryGallery></CloudinaryGallery> */}
      {/* <div style={{ width: 304, height: 171 }}></div>
      <ReactImageGallery imageUrls={photos}></ReactImageGallery> */}
    </>
  );
};

export default MainScreen;

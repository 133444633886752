import React, { useState, useEffect } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { baseUrl } from "../config/env";

const LogInScreen = () => {
  const location = useLocation();
  let [searchParams, setSearchParams] = useSearchParams();
  let [redirect, setRedirect] = useState("");

  const redirectGoogle = () => {
    if (redirect) {
      console.log(
        `${baseUrl}/api/auth/google?redirect=${redirect.substring(1)}`
      );
      window.location.href = `${baseUrl}/api/auth/google?redirect=${redirect.substring(
        1
      )}`;
    } else {
      console.log(`${baseUrl}/api/auth/google`);
      window.location.href = `${baseUrl}/api/auth/google`;
    }
  };
  const redirectMicrosoft = () => {
    if (redirect) {
      window.location.href = `${baseUrl}/api/auth/microsoft?redirect=${redirect.substring(
        1
      )}`;
    } else {
      window.location.href = `${baseUrl}/api/auth/microsoft`;
    }
  };

  useEffect(() => {
    setRedirect(searchParams.get("redirect"));
  }, []);

  return (
    <>
      <div className="container justify-content-center col-md-5 mt-4">
        <h2 className="text-center mb-4">Continue to SoloStreamer</h2>
        <div class="vstack gap-2 ">
          <button
            type="button"
            // class="btn btn-light text-dark border-dark "
            class="btn btn-light-gray p-2"
            onClick={() => redirectGoogle()}
          >
            <div class="d-flex align-items-center justify-content-center">
              <img
                src="https://res.cloudinary.com/dszd4owdm/image/upload/v1709773598/kisspng-g-suite-pearl-river-middle-school-google-docs-soft-google-plus-5ad4f155b36555.6827254815239048537348_eunaou.jpg"
                style={{ width: 24, height: 24, marginRight: 8 }}
                // className="me-2"
              ></img>
              Continue with Google&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
          </button>

          <button
            type="button"
            class="btn btn-light-gray p-2"
            onClick={() => redirectMicrosoft()}
          >
            <div class="d-flex align-items-center justify-content-center">
              <img
                src="https://res.cloudinary.com/dszd4owdm/image/upload/v1709773598/microsoft-windows-logo-scalable-vector-graphics-microsoft-new-logo-simple_dcx7xp.jpg"
                style={{ width: 20, height: 20, marginRight: 10 }}
                // className="me-2"
              ></img>
              Continue with Microsoft
            </div>
          </button>
          {/* <p className="mt-3 mb-1">
            <strong>
              By continuing you are agreeing to be at least 18 years or older.
            </strong>
          </p> */}
          <p className="fw-light mt-3">
            <small>
              By continuing, you agree to the{" "}
              <span>
                <Link to="/terms">Terms of Service</Link>
              </span>{" "}
              and{" "}
              <span>
                <Link to="/privacy">Privacy Policy</Link>
              </span>{" "}
              and you are 18 years or older.
            </small>
          </p>
        </div>
      </div>
    </>
  );
};

export default LogInScreen;

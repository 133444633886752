import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "bootstrap";

import CloudinaryUploadWidget from "./CloudinaryUploadWidget";
import {
  editCreatorInformation,
  getCreatorPageInformation,
} from "../actions/userActions";

const EditBackgroundPhotoModal = ({ creatorInformation }) => {
  const modalRef = useRef();
  const dispatch = useDispatch();

  const { backgroundPhoto: creatorBackgroundPhoto, userName } =
    creatorInformation;
  const [backgroundPhoto, setBackgroundPhoto] = useState("");

  const editedCreatorInformation = useSelector(
    (state) => state.editCreatorInformation
  );
  const { loading } = editedCreatorInformation;

  const handleEditProfile = async () => {
    const profileData = {
      backgroundPhoto: backgroundPhoto,
    };
    await dispatch(editCreatorInformation(profileData));
    closeModal();
    dispatch(getCreatorPageInformation(userName));
  };

  const closeModal = () => {
    let modal = document.getElementById(
      "editProfileBackgroundModalCloseButton"
    );
    modal.click();
  };
  useEffect(() => {
    var myModal = modalRef.current;
    var bsModal = new Modal(myModal);
    setBackgroundPhoto(creatorBackgroundPhoto ? creatorBackgroundPhoto : "");
  }, [creatorInformation]);
  return (
    <div
      class="modal fade"
      id="editBackgroundModal"
      tabIndex="-1"
      ref={modalRef}
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="editBackgroundModalLabel">
              Change background photo
            </h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="editProfileBackgroundModalCloseButton"
            ></button>
          </div>
          <div class="modal-body">
            <img src={backgroundPhoto} className="img-fluid"></img>
            <div className="mt-3">
              <CloudinaryUploadWidget
                returnPhoto={setBackgroundPhoto}
                folderName={`solostreamerProfilePictures/user`}
                className={"btn btn-outline-dark "}
                content={"Upload new background photo"}
                croppingAspectRatio={16 / 9}
              ></CloudinaryUploadWidget>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            {loading ? (
              <button class="btn btn-primary text-white" disabled>
                <span class="spinner-border spinner-border-sm me-2"></span>
                <span role="status">Save changes</span>
              </button>
            ) : (
              <button
                type="button"
                class="btn btn-primary text-white"
                onClick={() => handleEditProfile()}
              >
                Save changes
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditBackgroundPhotoModal;

import {
  CANCEL_USER_SUBSCRIPTION_FAIL,
  CANCEL_USER_SUBSCRIPTION_REQUEST,
  CANCEL_USER_SUBSCRIPTION_SUCCESS,
  GET_SUBSCRIPTION_SCREEN_INFO_FAIL,
  GET_SUBSCRIPTION_SCREEN_INFO_REQUEST,
  GET_SUBSCRIPTION_SCREEN_INFO_SUCCESS,
  GET_USER_SUBSCRIPTION_SCREEN_INFO_FAIL,
  GET_USER_SUBSCRIPTION_SCREEN_INFO_REQUEST,
  GET_USER_SUBSCRIPTION_SCREEN_INFO_SUCCESS,
} from "../constants/subscriptionConstants";

export const subscriptionCreationScreenReducer = (
  state = {
    loading: false,
    creatorInformation: null,
    subscriptionPlan: null,
    customer: null,
  },
  action
) => {
  switch (action.type) {
    case GET_SUBSCRIPTION_SCREEN_INFO_REQUEST:
      return {
        loading: true,
        creatorInformation: null,
        subscriptionPlan: null,
        customer: null,
      };
    case GET_SUBSCRIPTION_SCREEN_INFO_SUCCESS:
      return {
        loading: false,
        creatorInformation: action.payload.creatorInformation,
        subscriptionPlan: action.payload.subscriptionPlan,
        customer: action.payload.customer,
      };
    case GET_SUBSCRIPTION_SCREEN_INFO_FAIL:
      return {
        loading: false,
        creatorInformation: null,
        subscriptionPlan: null,
        customer: null,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const subscriptionScreenInformationReducer = (
  state = {
    loading: false,
    activeSubscriptions: [],
    cancelledSubscriptions: [],
    sentTips: [],
  },
  action
) => {
  switch (action.type) {
    case GET_USER_SUBSCRIPTION_SCREEN_INFO_REQUEST:
      return {
        loading: true,
        activeSubscriptions: [],
        cancelledSubscriptions: [],
        sentTips: [],
      };

    case GET_USER_SUBSCRIPTION_SCREEN_INFO_SUCCESS:
      return {
        loading: false,
        activeSubscriptions: action.payload.activeSubscriptions,
        cancelledSubscriptions: action.payload.cancelledSubscriptions,
        sentTips: action.payload.sentTips,
      };
    case GET_USER_SUBSCRIPTION_SCREEN_INFO_FAIL:
      return {
        loading: false,
        activeSubscriptions: [],
        cancelledSubscriptions: [],
        sentTips: [],
        error: action.payload,
      };

    default:
      return state;
  }
};

export const cancelSubscriptionReducer = (
  state = {
    loading: false,
    message: null,
  },
  action
) => {
  switch (action.type) {
    case CANCEL_USER_SUBSCRIPTION_REQUEST:
      return {
        loading: true,
        message: null,
      };
    case CANCEL_USER_SUBSCRIPTION_SUCCESS:
      return {
        loading: false,
        message: action.payload.message,
      };

    case CANCEL_USER_SUBSCRIPTION_FAIL:
      return {
        loading: false,
        message: null,
        error: action.payload,
      };

    default:
      return state;
  }
};

import React, { useRef, useEffect } from "react";
import { Toast } from "bootstrap";
import { useNavigate } from "react-router";

const ToastAlert = ({ message, header }) => {
  const navigate = useNavigate();
  const toastRef = useRef();
  useEffect(() => {
    var myToast = toastRef.current;
    var bsToast = new Toast(myToast);
    bsToast.show();
  }, []);
  const hideToast = () => {
    var myToast = toastRef.current;
    var bsToast = Toast.getInstance(myToast);
    bsToast.hide();
  };
  return (
    <div className="toast" role="alert" data-bs-delay="3000" ref={toastRef}>
      <div className="toast-header">
        <img
          src="https://res.cloudinary.com/dx4nlfeak/image/upload/v1713041081/Black_White_Minimalist_Business_Logo_lpjmkm.jpg"
          className="rounded-circle me-2"
          style={{ height: 25, width: 25, zIndex: 30 }}
        ></img>
        <strong className="me-auto">SoloStreamer</strong>
        <small>just now</small>
        <button
          type="button"
          className="btn-close"
          onClick={() => hideToast()}
        ></button>
      </div>
      <div className="toast-body p-0">
        <div class="p-3 text-center">
          <h5 class="mb-0">{header}</h5>
          <p class="mb-0">{message}</p>
        </div>
      </div>
    </div>
  );
};

export default ToastAlert;

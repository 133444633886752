import React from "react";
import { Link } from "react-router-dom";

const NotFoundScreen = () => {
  return (
    <section class="py-5 text-center container">
      <div class="row py-lg-5">
        <div class="col-lg-6 col-md-8 mx-auto">
          <h2 class="text-body-emphasis">
            Oops! We can't find the page you are looking for.
          </h2>
          <p class="lead text-body-secondary">
            Here are some helpful links instead:
          </p>
          <p>
            <Link to="/" class="link-primary">
              Go to home
            </Link>
          </p>
          <p>
            <Link to="/login" class="link-primary">
              Go to login
            </Link>
          </p>
        </div>
      </div>
    </section>
  );
};

export default NotFoundScreen;
